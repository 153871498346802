import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LoaderInnerWrapper = styled(Box)<{ overlay: string }>(
	({ overlay, theme }) => ({
		position: 'absolute',
		top: 0,
		left: 0,
		backgroundColor: overlay === "true"
			? 'rgba(255, 255, 255, 0.5) !important'
			: 'transparent',
		width: '100vw',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
		zIndex: overlay ? 2 : 1,

		'& .MuiCircularProgress-colorPrimary': {
			color: theme.palette.primary.main,
		},
	})
)

export const HideWrapper = styled(Box)<{ hide: string }>(({ hide }) => ({
	display: hide === "true" ? 'none' : 'initial',
}))

export const LogoWrapper = styled(Box)({
	marginRight: '5rem',
	transform: 'translateY(6px)',
})
