/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
	tooltipClasses,
	TooltipProps,
	Tooltip as MuiTooltip,
	styled,
} from '@mui/material'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
	<MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.primary.main,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.primary.main,
		fontSize: '0.9rem',
	},
}))


export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#4c4b4b',
		color: theme.palette.common.white,
		boxShadow: theme.shadows[1],
		fontSize: 12,
		lineHeight: 0.9,
		borderRadius: '0px'
	},
}));

function Tooltip({ title, children, ...props }: TooltipProps) {
	return (
		<StyledTooltip arrow title={title} {...props}>
			{children}
		</StyledTooltip>
	)
}

export default Tooltip
