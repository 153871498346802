import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const AbsoluteClose = styled(Close)({
	position: 'absolute',
	top: 20,
	right: 20,
	borderRadius: 10,
	cursor: 'pointer',
})

export const DialogTitleText = styled(Typography)(({ theme }) => ({
	marginTop: '10px',
	textAlign: 'center',

	[theme.breakpoints.down('md')]: {
		marginTop: '30px',
	},
}))

export const DialogSpace = styled(Dialog)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'flex-start',
})

export const DialogContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: 30,
})

export const DialogContentGrid = styled(Grid)(({ theme }) => ({
	padding: 20,
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'column',
	marginTop: '20px',

	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
		marginTop: '20px',
	},
}))

export const CssTextField = styled(TextField)({
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#C4C4C4',
		},
		'&:hover fieldset': {
			borderColor: '#000000',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#55C1E2',
		},
	},
})

export const InfoText = styled(Typography, {
	shouldForwardProp: prop => prop !== 'isError' && prop !== 'isSuccess',
})<{ isError?: boolean; isSuccess?: boolean }>(
	({ isError = false, isSuccess = false }) => ({
		// eslint-disable-next-line no-nested-ternary
		color: isError ? '#ff0000' : isSuccess ? 'green' : 'inherit',
		// eslint-disable-next-line no-nested-ternary
		textAlign: isError ? 'left' : isSuccess ? 'center' : 'inherit',
		fontSize: 'medium',
		fontWeight: 100,
	}),
)

export const CustomLoadingButton = styled(LoadingButton)(({ theme }) => ({
	backgroundColor: `#55C1E2 !important`,
	color: `${theme.palette.common.white} !important`,
	width: '100%',
	height: '70.5px !important',
	cursor: 'pointer !important',
	border: `1px solid #55C1E2`,
	padding: '5px 15px !important',

	'&:hover': {
		backgroundColor: theme.palette.common.white,
		color: `${theme.palette.primary.main} !important`,
		border: `1px solid ${theme.palette.primary.main}`,
		div: {
			color: `${theme.palette.primary.main} !important`,
		},
	},
	'&>div': {
		color: `${theme.palette.common.white} !important`,
		left: '66%',
		top: '39%',
	},
	'&>p': {
		fontSize: '0.875rem',
		lineHeight: '1.75',
		letterSpacing: '0.875px',
		fontWeight: 500,
	},
	'&>div>span': {
		width: '0.875rem !important',
		height: '0.875rem !important',
	},
	'& .MuiLoadingButton-loadingIndicator': {
		margin: '0 0 0 15px',
	},
}))

export const AssignDepartmentButton = styled(Button)({
	border: '1px solid #55C1E2',
	backgroundColor: '#55C1E2',
	width: '100%',
	height: '70.5px !important',
	color: '#FFF',
	'&:hover': {
		border: '1px solid #55C1E2',
		backgroundColor: '#FFF',
		color: '#55C1E2',
	},
})
