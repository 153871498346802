/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum StatusStateInText {
    Failed = 'Failed',
    Killed = 'Killed',
    Passed = 'Passed',
    Pending = 'Pending',
    Running = 'Running',
    Waiting = 'Waiting',
    ReadyForTest = 'ReadyForTest',
    Untested = 'Untested',
    Error = 'error',
}

export enum ChallengeType {
    Live = 'live',
    Battle = 'battle',
    Normal = 'normal',
    Unrestricted = "unrestricted", // this unrestricted is to turn off subscription check let's say for view source code in battle feature
    Signature = "signature"
}

export enum BattleView {
    ReadOnly = 'readonly',

}

export enum GameType {
    Normal = 'normal',
    Unrestricted = "unrestricted" // this unrestricted is to turn off subscription check let's say for view source code in battle feature
}

export enum GameMode {
    Company = 'Company',
    Single = 'Single',
    Multi = 'Multi',
    Catalog = 'Catalog',
    Sandbox = 'Sandbox'
}

export enum WorkspaceStatus {
    Pending = "pending",
    Creating = "creating",
    Started = "started",
    Created = "created",
    Starting = "starting",
    Ready = "ready",
    Deleted = "deleted",
    Error = "error",
}

export enum Likelihood {
    Low = "low",
    Medium = "medium",
    High = "high",
    NA = "n/a",
}

export enum Impact {
    Low = "low",
    Medium = "medium",
    High = "high",
    NA = "n/a",
}

export enum BattleActivityType {
    Hacked = "hacked",
    Deployed = "deployed",
    Redeployed = "redeployed",
    AttemptedToHack = "attemptedToHack", // this is basically when player tries to hack an app
}

export enum WSEventType {
    JoinInBattleGroupWS = "JoinInBattleGroupWS",
    LeaveBattleGroupWS = "LeaveBattleGroupWS"
}

export const StatusStateInNumber = {
    Loading: -1,
    NotStarted: 0,
    Running: 1,
    Failed: 2,
    Passed: 3,
    Waiting: 4,
    Untested: 5,
    Pending: 6,
    ReadyForTest: 7
};

export const getStatusStateInNumber = (status: string) => {
    if (status === "Loading") {
        return -1
    }
    if (status === "NotStarted") {
        return 0
    }
    if (status === "Running") {
        return 1
    }
    if (status === "Failed") {
        return 2
    }
    if (status === "Passed") {
        return 3
    }
    if (status === "Waiting") {
        return 4
    }
    if (status === "Untested") {
        return 5
    }
    if (status === "Pending") {
        return 6
    }
    return 7
}

export enum VisibilityModes {
    PUBLIC = "PUB",
    PRIVATE = "PRI",
    EXCLUSIVE = "EXC",
    EXAM = "EXM"
}

export enum ChallengeCategory {
	Expired = 'Expired',
	Passed = 'Passed',
	Certificate = 'Certificate',
	Featured = 'Featured',
	Battle = 'Battle',
	Signature = 'Signature',
	New = 'New',
	None = 'none',
	IsFirstPublishedAppStillUnhacked = 'IsFirstPublishedAppStillUnhacked',
	isThisAppPublisherAHunter = 'isThisAppPublisherAHunter',
}

export enum LoadButtonWrapper {
    Default = 'default',
    Hidden = 'hidden',
}

export enum HintPermission {
    Public = 'public',
    Private = 'private',
    Exclusive = 'exclusive',
}

export enum HintKind {
    Adoc = 'adoc',
    Solution = 'solution',
}

export function StatusNamingConversion(input: string) {
    let out = 'Loading'
    switch (input) {
        case "running":
            out = 'Running';
            break;
        case "killed":
        case "failure":
            out = 'Failed';
            break;
        case "success":
            out = 'Passed'
            break;
        default:
            out = 'Pending'
    }
    return out;
}

// out date
export const CommitType = {
    Loading: -1,
    Untested: 0,
    Testing: 1,
    Failed: 2,
    None: 3,
    Insufficient: 4,
};

export const AutoTestState = {
    Enabled: "true",
    Disabled: "false"
}

export enum PlayerType {
    General = "General",
    Exclusive = "Exclusive"
}

export enum Language {
    Python = "python",
    Java = "java",
    Csharp = "csharp",
    Go = "go",
    Javascript = "javascript",
    Typescript = "typescript",
    Ruby = "ruby",
}

export enum VulnerabilityFeatureCategory {
    Search = "Search",
    Technology = "Technology",
    Language = "Language",
    CWE = "CWE",
    OWASP = "OWASP",
    Severity = "Severity",
    Likelihood = "Likelihood",
    Impact = "Impact"
}

export enum TimePeriod {
    Default = "All Time",
    Last7Days = "Last 7 days",
    Last30Days = "Last 30 days",
    Last90Days = "Last 90 days",
    Last6Months = "Last 6 months",
    Last1Year = "Last 1 year",
}

export enum Department {
    Default = "All Departments"
}

export enum Severity {
    Informational = "informational",
    Low = "low",
    Medium = "medium",
    High = "high",
    Critical = "critical",
}

export enum Tags {
	Trivial = 'Trivial',
	Easy = 'Easy',
	Medium = 'Medium',
	Hard = 'Hard',
	Featured = 'Featured',
	TimedMode = 'TimeEnforced',
}

export const visibilityDisplay = (visibilityEnumValue: string) => {
    switch (visibilityEnumValue) {
        case 'PUB':
            return 'Public'
        case 'PRI':
            return 'Private'
        case 'EXC':
            return 'Exclusive'
        case 'EXM':
            return 'Unlisted'
        default:
            return ''
    }
}

export function parseCommitType(input: number) {

    let out = 'Loading'

    switch (input) {
        case 0:
            out = 'Untested';
            break;
        case 1:
            out = 'Testing';
            break;
        case 2:
            out = 'Failed';
            break;
        case 3:
            out = 'None'
            break;
        case 4:
            out = 'Insufficient'
            break;
        default:
            out = 'Loading';
            break;
    }
    return out;
}

