import * as React from 'react';
import { Box, Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import * as Styled from './RemoveCompanyPlayerConfirmationDialogStyles'
import playBackendRequestHandler from '../../../../../../api/playBackendRequestHandler';


export default function RemoveCompanyPlayerConfirmationDialog({ openRemoveCompanyPlayerConfirmationDialogInfo, handleCloseRemoveCompanyPlayerConfirmationDialog }: RemoveCompanyPlayerConfirmationDialogProps) {
    const handleYesRemoveUserAction = () => {
        playBackendRequestHandler(
            'companyPlayerSubscriptionCancel',
            {
                company_player_username: openRemoveCompanyPlayerConfirmationDialogInfo.playerUsername,
                company_player_email: openRemoveCompanyPlayerConfirmationDialogInfo.playerEmail,
            },
            undefined,
            undefined
        )
        handleCloseRemoveCompanyPlayerConfirmationDialog()
        window.location.reload()
    }
    return (
        <Box>
            <Styled.CustomDialog
                open={openRemoveCompanyPlayerConfirmationDialogInfo.isDialogShown}
                onClose={handleCloseRemoveCompanyPlayerConfirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to remove the following user from your company subscription:
                        <br />
                        <strong>Username: </strong>{openRemoveCompanyPlayerConfirmationDialogInfo.playerUsername}<br /><strong>Email: </strong>{openRemoveCompanyPlayerConfirmationDialogInfo.playerEmail}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRemoveCompanyPlayerConfirmationDialog}>No</Button>
                    <Button onClick={handleYesRemoveUserAction} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Styled.CustomDialog>
        </Box>
    );
}
