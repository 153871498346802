/* eslint-disable no-nested-ternary */

import { useState } from "react";
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import * as Styled from './AlertMessageStyles'

function AlertMessage({ isAlertShown, alertMessage, alertLink }: AlertProps) {

  const [open, setOpen] = useState(isAlertShown)

  const onClickSubscribeButton = () => {
    sessionStorage.setItem('redirectURI', window.location.href)
    window.location.href = `${process.env.REACT_APP_SUBSCRIPTION_REDIRECT}?origin=play`
  }

  return (
    <Styled.AlertMessageWrapper>
      <Collapse in={open}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {alertMessage.includes("You have run out of challenge attempts") ?

          <Styled.AlertBody
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  sessionStorage.setItem("globalAlertClosed", "true")
                  setOpen(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <Styled.AlertMessage>{alertMessage}<Styled.AlertLink onClick={onClickSubscribeButton}>{alertLink}</Styled.AlertLink></Styled.AlertMessage>
          </Styled.AlertBody>
          :
          // eslint-disable-next-line no-nested-ternary
          alertMessage.includes("You have successfully subscribed. It may take a few minutes for your subscription to be applied.") ?
            <Styled.AlertBody
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    sessionStorage.removeItem("subscriptionCheckout")
                    setOpen(false)
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <Styled.AlertMessage>{alertMessage}</Styled.AlertMessage>
            </Styled.AlertBody>
            :
            alertMessage.includes("There was an error in establishing Web Socket. Please refresh the page. If the error persists, please check your network connection") ?
              <Styled.AlertBody
                sx={{}}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      sessionStorage.setItem("globalAlertClosed", "true")
                      setOpen(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <Styled.AlertMessage>{alertMessage}<Styled.AlertLink onClick={onClickSubscribeButton}>{alertLink}</Styled.AlertLink></Styled.AlertMessage>
              </Styled.AlertBody>
              :
              alertMessage.includes("Ops! Your app has been hacked, try again") ?
                <Styled.AlertBody
                  sx={{ left: 0, mb: 2 }}
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        sessionStorage.setItem("hackedAlertClosed", "true")
                        sessionStorage.setItem("globalAlertClosed", "true")
                        setOpen(false)
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Styled.AlertMessage>{alertMessage}</Styled.AlertMessage>
                </Styled.AlertBody>
                :
                alertMessage.includes("A similar sandbox for this challenge has been tried. You can still try this challenge, however, the challenge score will not be given.") ?
                  <Styled.AlertBody
                    sx={{ left: 0, mb: 2 }}
                    severity="warning"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          sessionStorage.setItem("sandboxOpenAlertClosed", "true")
                          sessionStorage.setItem("globalAlertClosed", "true")
                          setOpen(false)
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <Styled.AlertMessage>{alertMessage}</Styled.AlertMessage>
                  </Styled.AlertBody>
                  :
                  <Styled.AlertBody
                    severity="warning"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          sessionStorage.setItem("globalAlertClosed", "true")
                          setOpen(false)
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    <Styled.AlertMessage>{alertMessage}<Styled.AlertLink onClick={onClickSubscribeButton}>{alertLink}</Styled.AlertLink></Styled.AlertMessage>
                  </Styled.AlertBody>

        }
      </Collapse>
    </Styled.AlertMessageWrapper>
  );
}

export default AlertMessage
