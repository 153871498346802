import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

// eslint-disable-next-line import/prefer-default-export
export const TableBox = styled(Box)(({ theme }) => ({
	padding: '0 0',
	paddingRight: '0',

	'& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
		outline: 'none !important',
	},

	'& .MuiDataGrid-columnHeader:last-child': {
		'& .MuiDataGrid-columnSeparator': {
			display: 'none',
		},
	},

	'& .MuiDataGrid-footerContainer': {
		height: '40px !important',
		minHeight: '0px',
	},

	'& .css-7g6wwl-MuiInputBase-root-MuiTablePagination-select': {
		marginRight: '8px',
	},

	[theme.breakpoints.down('sm')]: {
		padding: 0,
	},

	'& .MuiDataGrid-row': {
		cursor: 'pointer',
	},
}))

export const ProgressBarContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	width: '100%',
	height: 30,
	borderRadius: 2,
	margin: '5px 0',
	overflow: 'hidden',
	position: 'relative',
	border: `1px solid ${theme.palette.divider}`,
}))

export const ProgressBarValue = styled('div')({
	position: 'absolute',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: '#fff',
	fontSize: '12px',
})

export const ProgressBarBar = styled('div')(() => ({
	fontWeight: 'bolder',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
}))
