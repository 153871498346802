import { AccountCircleOutlined, DesignServices, Menu as MenuIcon, EmojiEvents, Feedback, Settings, Logout, AccountCircle, Business } from '@mui/icons-material'
import { AppBar, Box, Button, Menu, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

export const AppHeader = styled(AppBar)(({ theme }) => ({
	position: 'sticky',
	backgroundColor: theme.palette.common.white,
	flexDirection: 'row',
	justifyContent: 'space-between',
	width: '100%',
	padding: '15px 24px',
	color: theme.palette.common.black,

	[theme.breakpoints.down('md')]: {
		padding: '20px 24px',
	},

	[theme.breakpoints.down('sm')]: {
		padding: '20px 16px',
	},
}))

export const UserProfileImage = styled('img')(() => ({
	width: '120px',
	height: '120px',
	objectFit: 'cover',
	borderRadius: "100%",
}))

export const UserProfileImageHeader = styled('img')(() => ({
	width: '40px',
	height: '40px',
	objectFit: 'cover',
	borderRadius: "100%",
	cursor: 'pointer',
}))

export const Username = styled(Typography)(() => ({
	marginTop: '2px',
	marginLeft: '10px',
	color: "#000000",
	fontSize: '18px'
}))

export const Subscription = styled(Typography)(() => ({
	marginTop: '2px',
	marginLeft: '10px',
	color: '#000000',
	fontSize: '18px',
}))

export const NameWrapper = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column'
}))

export const UserFullName = styled(Typography)(() => ({
	fontWeight: '500',
	marginTop: "5px"
}))

export const CustomPerson = styled('img')(({ theme }) => ({
	color: `${theme.palette.primary.main}`,
	objectFit: 'cover',
	width: '120px',
	height: '120px',
	borderRadius: "100%",
}))

export const LeftContainer = styled(Box)({
	display: 'flex',
	alignItems: 'center',
})

export const UserNavLink = styled('a')({
	color: 'rgba(0, 0, 0, 0.87)',
	textDecoration: 'none',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center'
})

export const UserNavLinkText = styled(Typography)({
	marginLeft: '10px',
	marginTop: '0px',
	fontSize: '1rem'
})

export const CustomFeedback = styled(Feedback)({
	fontSize: '23px'
})

export const CustomSettings = styled(Settings)({
	fontSize: '23px'
})

export const CustomBuild = styled(DesignServices)({
	fontSize: '23px'
})


export const CustomBusiness = styled(Business)({
	fontSize: '23px'
})

export const CustomLogout = styled(Logout)({
	fontSize: '23px'
})

export const CustomAccountCircle = styled(AccountCircle)({
	fontSize: '23px'
})

export const CustomMenu = styled(Menu)({
	'& .Mui-disabled ': {
		opacity: "1 !important"
	}
})

export const NotificationBox = styled(Box)({
	position: 'absolute',
	right: '-4px',
	top: '-8px',
	backgroundColor: '#b2e2f1',
	width: 23,
	height: 23,
	borderRadius: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
})

export const AccountWrapper = styled(Box)({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
})

export const AccountIcon = styled(AccountCircleOutlined)({
	width: 40,
	height: 40,
	cursor: 'pointer',
})

export const LogoWrapper = styled(Box)({
	width: 50,
	height: 50,

	'& > svg': {
		width: '100%',
		height: '100%',
	},
	'&:hover': {
		cursor: 'pointer',
	}
})

export const MenuItem = styled(Link)(({ theme }) => ({
	marginLeft: 13,
	marginRight: 5,
	fontSize: 16,
	fontWeight: 300,
	color: theme.palette.primary.main,
	textDecoration: 'none',

	[theme.breakpoints.down('md')]: {
		display: 'none',
	},

	opacity: 1
}))

export const MenuRedirectItem = styled('a')(({ theme }) => ({
	marginLeft: 13,
	fontSize: 16,
	fontWeight: 300,
	color: theme.palette.text.secondary,
	textDecoration: 'none',

	[theme.breakpoints.down('md')]: {
		display: 'none',
	},

	'&:hover': {
		color: theme.palette.primary.main,
	}
}))

export const MenuFirstRedirectItem = styled('a')(({ theme }) => ({
	marginLeft: 34,
	fontSize: 16,
	fontWeight: 300,
	color: theme.palette.text.secondary,
	textDecoration: 'none',

	[theme.breakpoints.down('md')]: {
		display: 'none',
	},

	'&:hover': {
		color: theme.palette.primary.main,
	}
}))

export const MenuInformationItem = styled(Typography)(({ theme }) => ({
	padding: 6,
	paddingLeft: 15,
	paddingRight: 10,
	color: theme.palette.primary.main,
}))

export const MenuImportantItem = styled(Typography)(({ theme }) => ({
	padding: 6,
	paddingLeft: 15,
	paddingRight: 10,
	color: theme.palette.error.main,
}))

export const MenuLinkItem = styled('a')(({ theme }) => ({
	padding: 6,
	paddingLeft: 15,
	paddingRight: 10,
	color: theme.palette.primary.main,
	textDecoration: 'none',
	'&:hover': {
		textDecoration: 'underline',
	}
}))

export const UserPanel = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
	width: '100%',
})

export const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
	marginLeft: 24,
	cursor: 'pointer',

	[theme.breakpoints.up('md')]: {
		display: 'none',
	},

	[theme.breakpoints.down('sm')]: {
		marginLeft: 16,
	},
}))

export const DrawerInnerContainer = styled(Box)({
	width: 242,
	padding: '36px 16px',
})

export const DrawerOptionLink = styled(Link)(({ theme }) => ({
	display: 'block',
	marginBottom: 16,
	fontSize: 16,
	fontWeight: 300,
	color: theme.palette.primary.main,
	textDecoration: 'none',
}))

export const DrawerOption = styled('a')(({ theme }) => ({
	display: 'block',
	marginBottom: 16,
	fontSize: 16,
	fontWeight: 300,
	color: theme.palette.text.secondary,
	textDecoration: 'none',
	'&:hover': {
		color: theme.palette.primary.main,
	}
}))

export const SignInButton = styled(Button)(({ theme }) => ({
	width: 144,
	marginRight: 20,
	padding: 12,
	borderColor: theme.palette.primary.main,
	fontSize: '0.75rem',
	fontWeight: 600,
	letterSpacing: '0.875px',
	marginLeft: 8,
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},

	[theme.breakpoints.down('md')]: {
		width: 124,
		margin: 0,
	},
}))

export const BuildButton = styled(Button)(() => ({
	padding: '6px 12px 6px 0px',
	'&:hover': {
		backgroundColor: "transparent",
	},
}))

export const HallOfFameButton = styled(Button)(() => ({
	padding: '6px 0px 6px 8px',
	'&:hover': {
		backgroundColor: "transparent",
	},
}))

export const SignInLoadingButton = styled(SignInButton)(({ theme }) => ({
	paddingLeft: 25,
	borderColor: `${theme.palette.primary.main} !important`,
	color: `${theme.palette.primary.main} !important`,
	marginLeft: '8px',
	'&:hover': {
		backgroundColor: `${theme.palette.primary.main} !important`,
		color: `${theme.palette.common.white} !important`,
	},
	'#signInLoadingIcon>div': {
		color: `${theme.palette.primary.main} !important`,
	},
	'&:hover #signInLoadingIcon>div': {
		color: `${theme.palette.common.white} !important`,
	}
}))


export const WorkshopButton = styled('a')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: theme.palette.primary.main,
	width: 157,
	marginRight: 20,
	padding: 12,
	border: `1px solid ${theme.palette.primary.main}`,
	borderRadius: 4,
	color: theme.palette.common.white,
	fontSize: '0.75rem',
	fontWeight: 600,
	textDecoration: 'none',
	textTransform: 'uppercase',
	letterSpacing: '0.875px',
	transition:
		'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

	'&:hover': {
		backgroundColor: `${theme.palette.primary.main}10`,
		color: theme.palette.primary.main,
	},

	[theme.breakpoints.down('md')]: {
		display: 'none',
	},
}))

export const NotificationText = styled(Typography)(({ theme }) => ({
	fontSize: '12px',
	color: theme.palette.common.white,
	fontWeight: 'bold',
}))

export const BuildIcon = styled(DesignServices)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: 35,
	height: 35,
}))

export const CustomEmojiEvents = styled(EmojiEvents)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: 35,
	height: 35,
}))
