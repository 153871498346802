import ErrorPage from '../../shared/components/ErrorPage/ErrorPage'

function NotFound({ className, wip }: NotFoundProps) {
	return (
		<div className={className}>
			{wip ? (
				<ErrorPage
					errorTitle='Service is unavailable'
					errorMessage='Sorry, it is not implemented yet'
					errorIconName='Construction'
				/>
			) : (
				<ErrorPage
					errorTitle='404 Page not found'
					errorMessage='The page is temporarily unavailable.'
				/>
			)}
		</div>
	)
}
export default NotFound
