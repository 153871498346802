export const urls = {
    games: {
        endpoint: `play/games/`,
        type: 'GET'
    },
    game: {
        endpoint: `play/game/`,
        type: 'GET'
    },
    deleteGame: {
        endpoint: `play/game/`,
        type: 'DELETE'
    },
    gameChallenges: {
        endpoint: `play/game/`,
        type: 'GET'
    },
    challenge: {
        endpoint: `play/game/`,
        type: 'GET'
    },
    challengeAttempts: {
        endpoint: `play/game/`,
        type: 'GET'
    },
    challengeAttempt: {
        endpoint: `play/game/`,
        type: 'GET'
    },
    retrieveLatestFiveStatus: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    retrieveStatus: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    updateStatus: {
        endpoint: 'play/challenge/',
        type: 'PATCH'
    },
    cancelTest: {
        endpoint: 'play/game/',
        type: 'DELETE'
    },
    getFork: {
        endpoint: `play/game/`,
        type: 'GET'
    },
    fork: {
        endpoint: `play/game/`,
        type: 'POST'
    },
    fetchCommits: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    build: {
        endpoint: 'play/game/',
        type: 'POST'
    },
    sessionLogin: {
        endpoint: 'play/login/',
        type: 'POST'
    },
    sessionCheck: {
        endpoint: 'play/login/',
        type: 'POST'
    },
    heartbeat: {
        endpoint: 'play/hb/',
        type: 'GET'
    },
    logout: {
        endpoint: 'play/logout/',
        type: 'GET'
    },
    openidclient: {
        endpoint: 'play/ocid/',
        type: 'GET'
    },
    playUserInfo: {
        endpoint: 'play/userinfo/',
        type: 'GET'
    },
    top3players: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    top10players: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    retrieveRootRepositoryContentsURL: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    retrieveDirRepositoryContentsURL: {
        endpoint: 'play/game/',
        type: 'POST'
    },
    retrieveFileRepositoryContentsURL: {
        endpoint: 'play/game/',
        type: 'POST'
    },
    updateFileRepositoryContentsURL: {
        endpoint: 'play/game/',
        type: 'PUT'
    },
    hints: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    hint: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    leaderboard: {
        endpoint: 'play/leaderboard/',
        type: 'GET'
    },
    publishedAppList: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    hackedApp: {
        endpoint: 'play/game/',
        type: 'POST'
    },
    publishedAppSSHUrl: {
        endpoint: 'play/game/',
        type: 'POST'
    },
    battleActivities: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    company: {
        endpoint: 'play/company',
        type: 'GET'
    },
    companyPlayersActivities: {
        endpoint: 'play/company/players/activities',
        type: 'GET'
    },
    companyPlayersActivitiesBarChart: {
        endpoint: 'play/company/players/activities/monthly-engagement',
        type: 'GET'
    },
    companyPlayersActivitiesGrade: {
        endpoint: 'play/company/players/activities/grade',
        type: 'GET'
    },
    companyPlayerGitHistory: {
        endpoint: 'play/company/player/git/history',
        type: 'GET'
    },
    companyPlayerSubscriptionCancel: {
        endpoint: 'play/company/player/subscription/cancel',
        type: 'DELETE'
    },
    companyPlayersActivitiesPieChart: {
        endpoint: 'play/company/players/activities/skill-proficiency',
        type: 'GET'
    },
    companyPlayerRepositories: {
        endpoint: 'play/company/player/repositories',
        type: 'GET'
    },
    companyAdminGitCollaborationAdd: {
        endpoint: 'play/company/admin/git/collaboration/add',
        type: 'POST'
    },
    vulnerabilities: {
        endpoint: 'play/vuln/',
        type: 'GET'
    },
    vulnerability: {
        endpoint: 'play/vuln/',
        type: 'GET'
    },
    createWorkspaceForVulnerability: {
        endpoint: 'play/sandbox/',
        type: 'POST'
    },
    createWorkspaceForChallenge: {
        endpoint: 'play/game/',
        type: 'POST'
    },
    idUserInfo: {
        endpoint: 'id/account/user/',
        type: 'GET'
    },
    reward: {
        endpoint: 'play/game/',
        type: 'GET'
    },
    createDepartment: {
        endpoint: 'play/company/department/',
        type: 'POST'
    },
    batchUserUpdate: {
        endpoint: 'play/company/user/update/batch',
        type: 'POST'
    }
    // the following code is commented (used for subscription checkout call in ID) but since, 
    // id session period is limited, we are using the following code
    // createCheckoutSession: {
    //     endpoint: 'subscription/create-checkout-session/',
    //     type: 'POST'
    // },
}
export default urls