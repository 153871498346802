import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'

// eslint-disable-next-line import/prefer-default-export
export const Button = styled(MuiButton)<{ rounded: number }>(
	({ rounded, theme, variant }) => ({
		backgroundColor: variant === 'outlined' ? theme.palette.common.white : theme.palette.primary.main,
		borderRadius: rounded ? '5px' : '0',
		color: variant === 'outlined' ? theme.palette.primary.main : theme.palette.common.white,
		border: variant === 'outlined' ? `1px solid ${theme.palette.primary.main}` : 'none',

		'&:hover': {
			backgroundColor: variant === 'outlined' ?  theme.palette.primary.main : theme.palette.common.white,
			color: variant === 'outlined' ? theme.palette.common.white : theme.palette.primary.main,
		},
	}),
)
