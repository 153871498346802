import { CssBaseline, ThemeProvider } from '@mui/material'
import React, { Suspense } from 'react'
import 'react-calendar-heatmap/dist/styles.css'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import './assets/averta.css' // Averta font import
import './assets/style.css'
import theme from './utils/theme'
// @ts-ignore
import App from './App.tsx'
// @ts-ignore
import { initOIDC } from './api/auth'
import { store } from './redux/store'
import reportWebVitals from './reportWebVitals'
import Loader from './shared/components/Loader/Loader'

initOIDC()

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
	<React.StrictMode>
		<Suspense fallback={<Loader />}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Provider store={store}>
					<App />
				</Provider>
			</ThemeProvider>
		</Suspense>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
