import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChallengeHintListProps {
    hints: Hint[],
    isHintListDialogShown: boolean
}

const initialState: ChallengeHintListProps = { hints: [], isHintListDialogShown: false }
const challengeHintListSlice = createSlice({
    name: 'ChallengeHintListSlice',
    initialState,
    reducers: {
        setChallengeHintList: (state: ChallengeHintListProps, action: PayloadAction<ChallengeHintListProps>) => action.payload,
        setChallengeHintListDialogPrompt: (state: ChallengeHintListProps, action: PayloadAction<boolean>) => {
            state.isHintListDialogShown = action.payload // eslint-disable-line no-param-reassign
        },
        setChallengeHintUnlocked: (state: ChallengeHintListProps, action: PayloadAction<Hint>) => {
            state.hints.forEach((hint: Hint) => {
                if (hint.uuid === action.payload.uuid) {
                    // eslint-disable-next-line no-param-reassign
                    hint.unlocked = true
                }
            })
            return state
        },
    },
});
export const { setChallengeHintList, setChallengeHintListDialogPrompt, setChallengeHintUnlocked } = challengeHintListSlice.actions;
export default challengeHintListSlice.reducer;