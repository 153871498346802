import * as Styled from './SecDimButtonStyles'

function SecDimButton({
	className,
	fullWidth,
	onClick,
	disabled,
	children,
	id,
	variant,
	disableRipple,
	rounded = false,
	sx,
}: SecDimBtnProps) {
	return (
		<div className={className} id={id}>
			<Styled.Button
				className={className}
				disableRipple={disableRipple}
				variant={variant || 'contained'}
				fullWidth={fullWidth}
				rounded={rounded ? 1 : 0}
				id='secdimBtn'
				onClick={onClick}
				disabled={disabled}
				sx={sx}
			>
				{children}
			</Styled.Button>
		</div>
	)
}
export default SecDimButton
