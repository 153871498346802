import { Close } from '@mui/icons-material'
import { Box, Dialog } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ButtonWrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	gap: '2%',
	justifyContent: 'center',
	width: '100%',
	marginTop: '2%',
})

export const TrialWrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	width: '100%',
	marginTop: '2%',
})

export const TrialDialog = styled(Dialog)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
})

export const AbsoluteClose = styled(Close)({
	position: 'absolute',
	top: 20,
	right: 20,
	borderRadius: 10,
	cursor: 'pointer',
})
