import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const TableBox = styled(Box)(({ theme }) => ({
	padding: '0 56px',

	'& .MuiDataGrid-columnHeader:last-child': {
		'& .MuiDataGrid-columnSeparator': {
			display: 'none',
		},
	},

	[theme.breakpoints.down('sm')]: {
		padding: 0,
	},

	'& .MuiDataGrid-row': {
		cursor: 'pointer'
	},
}))

export default TableBox
