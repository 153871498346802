import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import OpenIDContextProvider from './components/OpenIDContextProvider/OpenIDContextProvider'
import PlayRouter from './components/PlayRouter/PlayRouter'
import DisplayTrial from './shared/components/DisplayTrial/DisplayTrial'

const qClient = new QueryClient()

function App() {

	return (
		<OpenIDContextProvider className='full-height'>
			<QueryClientProvider client={qClient}>
				<ReactQueryDevtools initialIsOpen={false} />
				<DisplayTrial />
				<Router>
					<PlayRouter />
				</Router>
			</QueryClientProvider>
		</OpenIDContextProvider>
	)
}

export default App
