import { AxiosResponse } from 'axios'
import { UserManager, UserManagerSettings } from 'oidc-client-ts'
import { useCallback, useEffect, useState } from 'react'
import { OpenIDContext, codeResponse } from '../../api/auth'
import playBackendRequestHandler from '../../api/playBackendRequestHandler'
import { GameMode, PlayerType } from '../../enums'

function OpenIDContextProvider({ className, children }: DefaultProps) {
	const code = new URLSearchParams(window.location.search).get('code')
	const subscriptionCheckout = new URLSearchParams(window.location.search).get(
		'subscription-checkout',
	)
	const [isGlobalAlertShown, setIsGlobalAlertShown] = useState(false)
	const [gameAndChallengeRestriction, setGameAndChallengeRestriction] = useState(
		{
			isGameUnrestricted: false,
			isChallengeUnrestricted: false,
		},
	)
	const [profile, setProfile] = useState({
		authenticated: null as boolean | null,
		profile: {
			firstName: '',
			lastName: '',
			username: '',
			email: '',
			guid: '',
			location: '',
			profileImage: '',
			dateJoined: '',
			lastActive: '',
			isSubscribed: false,
			subscriptionType: '',
			playerTotalGameScore: 0,
			playerType: PlayerType.General,
			company: {
				name: '',
				logo: '',
				department: {
					name: '',
					slug: ''
				},
			},
			restrictions: {
				subscribedAttempts: 0,
				subscribedRemainingAttempts: 0,
				hasPrivateGameAccess: false,
				hasPrivateChallengeAccess: false,
				hasPrivateHintAccess: false,
				hasBattleChallengeAccess: false,
				hasBattleChallengeSourceCodeAccess: false,
				hasCSEAccess: false,
				hasSandboxAccess: false,
				hasOpenSandboxPermission: false,
				hasAddSandboxPermission: false,
				hasDeleteSandboxPermission: false,
				hasAddUserPermission: false,
				hasViewCompanyDashboardPermission: false,
				hasAddCompanyUserAPIKeyPermission: false,
				hasRevokeCompanyUserAPIKeyPermission: false,
				hasAddCompanyGamePermission: false,
				hasEditCompanyGamePermission: false,
				hasDeleteCompanyGamePermission: false,
				hasAddCompanyDepartmentPermission: false,
			},
		},
	})

	const processUserInfoResponse = (playUserInfo: AxiosResponse<PlayUserInfo, any>) => ({
		authenticated: true,
		profile: {
			firstName: playUserInfo.data.first_name,
			lastName: playUserInfo.data.last_name,
			username: playUserInfo.data.username,
			email: playUserInfo.data.email,
			guid: playUserInfo.data.guid,
			location: playUserInfo.data.location,
			profileImage: playUserInfo.data.profile_image,
			dateJoined: playUserInfo.data.date_joined,
			lastActive: playUserInfo.data.last_active,
			isSubscribed: playUserInfo.data.subscription,
			subscriptionType: playUserInfo.data.subscription_type,
			playerTotalGameScore: playUserInfo.data.player_total_game_score,
			playerType: playUserInfo.data.player_type,
			company: {
				name: playUserInfo.data.company.name ? playUserInfo.data.company.name : '',
				logo: playUserInfo.data.company.logo ? playUserInfo.data.company.logo : '',
				department: {
					name:
						playUserInfo.data.company.department &&
						playUserInfo.data.company.department.name
							? playUserInfo.data.company.department.name
							: '',
					slug:
						playUserInfo.data.company.department &&
						playUserInfo.data.company.department.slug
							? playUserInfo.data.company.department.slug
							: '',
				},
			},
			restrictions: {
				subscribedAttempts: playUserInfo.data.restrictions.subscribed_attempts,
				subscribedRemainingAttempts:
					playUserInfo.data.restrictions.subscribed_remaining_attempts,
				hasPrivateGameAccess: playUserInfo.data.restrictions.has_private_game_access,
				hasPrivateChallengeAccess:
					playUserInfo.data.restrictions.has_private_challenge_access,
				hasPrivateHintAccess: playUserInfo.data.restrictions.has_private_hint_access,
				hasBattleChallengeAccess:
					playUserInfo.data.restrictions.has_battle_challenge_access,
				hasBattleChallengeSourceCodeAccess:
					playUserInfo.data.restrictions.has_battle_challenge_source_code_access,
				hasCSEAccess: playUserInfo.data.restrictions.has_cse_access,
				hasSandboxAccess: playUserInfo.data.restrictions.has_sandbox_access,
				hasOpenSandboxPermission:
					playUserInfo.data.restrictions.has_open_sandbox_permission,
				hasAddSandboxPermission:
					playUserInfo.data.restrictions.has_add_sandbox_permission,
				hasDeleteSandboxPermission:
					playUserInfo.data.restrictions.has_delete_sandbox_permission,
				hasAddUserPermission: playUserInfo.data.restrictions.has_add_user_permission,
				hasViewCompanyDashboardPermission:
					playUserInfo.data.restrictions.has_company_dashboard_permission,
				hasAddCompanyUserAPIKeyPermission:
					playUserInfo.data.restrictions.has_add_company_user_api_key_permission,
				hasRevokeCompanyUserAPIKeyPermission:
					playUserInfo.data.restrictions.has_revoke_company_user_api_key_permission,
				hasAddCompanyGamePermission:
					playUserInfo.data.restrictions.has_add_company_game_permission,
				hasEditCompanyGamePermission:
					playUserInfo.data.restrictions.has_edit_company_game_permission,
				hasDeleteCompanyGamePermission:
					playUserInfo.data.restrictions.has_delete_company_game_permission,
				hasAddCompanyDepartmentPermission:
					playUserInfo.data.restrictions.has_add_company_department_permission,
			},
		},
	})

	const processSessionStorageItems = useCallback(
		(profileUpdate: any) => {
			if (sessionStorage.getItem('redirectURI') && subscriptionCheckout) {
				sessionStorage.setItem('subscriptionCheckout', subscriptionCheckout)
				window.location.href = sessionStorage.getItem('redirectURI')!
				sessionStorage.removeItem('redirectURI')
			}
			if (
				profileUpdate.authenticated &&
				profileUpdate.profile.playerType === PlayerType.Exclusive
			) {
				sessionStorage.setItem('rootRedirect', GameMode.Company)
			} else if (
				(profileUpdate.authenticated &&
					profileUpdate.profile.playerType === PlayerType.General) ||
				profileUpdate.authenticated === false
			) {
				sessionStorage.setItem('rootRedirect', GameMode.Single)
			}
		},
		[subscriptionCheckout],
	)

	const userInfo = useCallback(async () => {
		if (code) {
			codeResponse(window.location.href)
				.then(resp => {
					const idToken = resp.id_token // User's 'passport'
					const newURL = window.location.href.split('?')[0]
					playBackendRequestHandler('sessionLogin', undefined, idToken)
						.then(response => {
							if (response.status === 200) {
								if (sessionStorage.getItem('redirectURI')) {
									window.location.href = sessionStorage.getItem('redirectURI')!
									sessionStorage.removeItem('redirectURI')
								} else {
									window.history.pushState('object', document.title, newURL)
								}
							}
						})
						.catch(() => {
							window.history.pushState('object', document.title, newURL)
						})
				})
				.catch(err => {
					// eslint-disable-next-line no-console
					console.error(err)
				})
		}

		if (profile.authenticated === null) {
			const signInLoading = sessionStorage.getItem('silentLoginAlreadyAttempted') !== 'true'
			playBackendRequestHandler('playUserInfo').then(async playUserInfo => {
				if (playUserInfo.data.logged_in) {
					// User logged in, set user profile info
					const profileUpdate = processUserInfoResponse(playUserInfo)
					processSessionStorageItems(profileUpdate)
					setProfile(profileUpdate)
				} else if (
					signInLoading &&
					!playUserInfo.data.logged_in &&
					!code &&
					!profile.authenticated
				) {
					// User not logged in, and silent login not yet performed.
					setProfile(prevState => ({
						...prevState,
						authenticated: false,
					}))
					// the following settings are for silent login
					const clientID = (await playBackendRequestHandler('openidclient')).data.oclient_id
					const userManagerSettings: UserManagerSettings = {
						authority: `${process.env.REACT_APP_DJANGO_SECDIM_ID}openid`,
						client_id: clientID, // need to get this in dev env somehow...
						redirect_uri: window.location.origin,
						post_logout_redirect_uri: `${process.env.REACT_APP_SECDIM_ID_FRONTEND}account/logout`,
						response_type: 'code',
						scope: 'openid email',
						filterProtocolClaims: true,
						loadUserInfo: true,
						silent_redirect_uri: window.location.origin,
						silentRequestTimeoutInSeconds: 5,
					}
					sessionStorage.setItem('silentLoginAlreadyAttempted', 'true')
					const userManager: UserManager = new UserManager(userManagerSettings)
					userManager
						.signinSilent()
						.then(() => {
							// Refetch login state after silent loging 
							playBackendRequestHandler('playUserInfo').then(async silentLoginPlayUserInfo => {
								if (silentLoginPlayUserInfo.data.logged_in) {
									const profileUpdate = processUserInfoResponse(silentLoginPlayUserInfo)
									processSessionStorageItems(profileUpdate)
									setProfile(profileUpdate)
								}
							})
						})
						.catch((error: Error) => {
							// Refetch login state after silent login
							// eslint-disable-next-line
							console.error(error)
							playBackendRequestHandler('playUserInfo').then(
								async silentLoginPlayUserInfo => {
									if (silentLoginPlayUserInfo.data.logged_in) {
										const profileUpdate = processUserInfoResponse(silentLoginPlayUserInfo)
										processSessionStorageItems(profileUpdate)
										setProfile(profileUpdate)
									}
								},
							)
						})
				} else {
					// User not logged in, and silent login already attempted
					setProfile(prevState => ({
						...prevState,
						authenticated: false,
					}))
				}
			})
		}
	}, [code, processSessionStorageItems, profile.authenticated])

	useEffect(() => {
		userInfo()
	}, [userInfo])

	return (
		<div className={className}>
			<OpenIDContext.Provider
				// eslint-disable-next-line react/jsx-no-constructed-context-values
				value={{
					authenticated: profile.authenticated,
					profile: profile.profile,
					refresh: userInfo,
					isGlobalAlertShown,
					setIsGlobalAlertShown,
					gameAndChallengeRestriction,
					setGameAndChallengeRestriction,
				}}
			>
				{children}
			</OpenIDContext.Provider>
		</div>
	)
}
export default OpenIDContextProvider
