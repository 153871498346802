import axios, { AxiosResponse, Method } from 'axios'
import { OperationResponse, operations } from './operations'
import { urls } from './urls'

export const getCSRFToken = () => {
	const cookies = document.cookie.split('; ')
	const csrfTokenIndex = cookies.findIndex(item => item.startsWith('csrftoken'))

	if (csrfTokenIndex >= 0) {
		return cookies[csrfTokenIndex].replace('csrftoken=', '')
	}

	return ''
}

export default async function playBackendRequestHandler<T extends operations>(
	operation: T,
	data?: any,
	token?: string,
	appendURL?: string,
): Promise<AxiosResponse<OperationResponse[T]>> {
	const endpointDetails = urls[operation]
	const csrfToken = getCSRFToken()
	if (endpointDetails) {
		const headers: any = {
			Accept: 'application/json',
			'X-CSRFToken': csrfToken,
			'Content-type': 'application/json',
		}
		if (token) {
			headers.Authorization = `Bearer ${token}`
		}
		return axios({
			method: endpointDetails.type as Method,
			withCredentials: true,
			headers,
			url: `${process.env.REACT_APP_BASE_API_URL}${endpointDetails.endpoint}${
				appendURL || ''
			}`,
			data,
		})
	}
	throw new Error('Invalid Operation')
}
