import { ContentCopy } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    ThemeProvider,
    Dialog,
    Box,
    Popover,
    Typography
} from '@mui/material'
import copy from 'copy-to-clipboard'
import theme from '../../../utils/theme'
import * as Styled from './GitCloneDialogIDEStyles'
import VSCodeLogo from '../../../assets/vscode.svg'
import IntelliJLogo from '../../../assets/intellij.svg'
import { setChallengeDialogPrompt } from '../../../redux/challengeDialogPromptSlice'


function GitCloneDialogIDE({ forkURI, gitCloneDialogTitle, isIDEDialogVisible }: ChallengeDialogIDEProps) {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

    const handleCopy = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(e.currentTarget)
        copy(forkURI)
    }

    const zeroingAnchor = () => {
        setAnchorEl(null)
    }

    const handleCloseDialog = () => {
        dispatch(setChallengeDialogPrompt(false))
    }

    return <Dialog onClose={handleCloseDialog} open={isIDEDialogVisible}>
        <Styled.AbsoluteClose onClick={handleCloseDialog} />
        <Styled.DialogInnerWrapper>
            <ThemeProvider theme={theme}>
                <Box>
                    <Typography textAlign='center' variant='subtitle1'>
                        {gitCloneDialogTitle}
                    </Typography>
                    <Styled.CopyBoxWrapper onClick={handleCopy}>
                        <Styled.CopyBox>
                            <Styled.CopyBoxText
                                variant='standard'
                                id='forkURI'
                                defaultValue={forkURI}
                                InputProps={{ readOnly: true, disableUnderline: true }}
                            />
                        </Styled.CopyBox>
                        <Styled.CopyBtn>
                            <ContentCopy />
                        </Styled.CopyBtn>
                    </Styled.CopyBoxWrapper>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={zeroingAnchor}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Styled.CopiedText>Copied To Clipboard</Styled.CopiedText>
                    </Popover>
                    <Typography textAlign='center' variant='subtitle1'>
                        You need to add a <Styled.SshSetUpLink href={`${process.env.REACT_APP_SECDIM_ID_FRONTEND}account/sshkey`}>SSH public key</Styled.SshSetUpLink> to clone the repository.
                    </Typography>
                    <Styled.Border />
                    <Styled.IdeHeader>Open in your IDE</Styled.IdeHeader>
                    <Styled.IDEWrapper>
                        <Styled.VSCodeWrapper>
                            <Styled.VSCodeButton variant='outlined' onClick={() => { window.open(`vscode://vscode.git/clone?url=${forkURI}`) }}>
                                <img src={VSCodeLogo} alt='secdim Logo' />
                                Visual Studio Code
                            </Styled.VSCodeButton>
                        </Styled.VSCodeWrapper>
                        <Styled.IntelliJWrapper>
                            <Styled.IntelliJButton variant='outlined' onClick={() => { window.open(`jetbrains://idea/checkout/git?idea.required.plugins.id=Git4Idea&checkout.repo=${forkURI}`) }}>
                                <img src={IntelliJLogo} alt='secdim Logo' />
                                IntelliJ IDE
                            </Styled.IntelliJButton>
                        </Styled.IntelliJWrapper>
                    </Styled.IDEWrapper>
                </Box>
            </ThemeProvider>
        </Styled.DialogInnerWrapper>
    </Dialog >
}

export default React.memo(GitCloneDialogIDE)