import {
	Block,
	CloudOff,
	Construction,
	Dangerous,
	LockClock,
	SentimentVeryDissatisfied,
	SyncDisabled,
	VideogameAssetOff,
	Warning,
	RunningWithErrors,
	HourglassTop,
	SportsMartialArts
} from '@mui/icons-material'

const ErrorIconName = {
	CloudOff: <CloudOff />,
	VideogameAssetOff: <VideogameAssetOff />,
	Dangerous: <Dangerous />,
	SyncDisabled: <SyncDisabled />,
	Block: <Block />,
	LockClock: <LockClock />,
	Warning: <Warning />,
	Construction: <Construction />,
	RunningWithErrors: <RunningWithErrors />,
	HourglassTop: <HourglassTop />,
	SportsMartialArts: <SportsMartialArts />,
	Default: <SentimentVeryDissatisfied />,
}

export default ErrorIconName
