import {
	ArcElement,
	Chart as ChartJS,
	Legend,
	Tooltip
} from 'chart.js';
import 'chartjs-adapter-date-fns'; // yarn add date-fns chartjs-adapter-date-fns --save
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import { Department } from '../../../../enums';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const getOptions = () => ({
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'top' as const,
		},
		title: {
			display: true,
			text: 'Skill Proficiency'
		},
		datalabels: {
			color: 'white',
			display: (context: any) => context.dataset.data[context.dataIndex] !== 0,
			font: {
				weight: 600
			},
			formatter: (value: any, context: any) => `${context.dataset.data[context.dataIndex]}%`
		},
		tooltip: {
			enabled: false
		}
	},
})

/* This is the function that gets the information for the bar chart based on our given data set */
const getPieChartInfo = (companyPlayersActivitiesPieChartData: CompanyPlayersActivitiesPieChart[], department: string | undefined) => {
	let totalNumberOfSolvedTrivialChallenges = 0
	let totalNumberOfSolvedEasyChallenges = 0
	let totalNumberOfSolvedMediumChallenges = 0
	let totalNumberOfSolvedHardChallenges = 0

	// Filter data by department if provided 
	if (department && department !== Department.Default) {
		// eslint-disable-next-line no-param-reassign
		companyPlayersActivitiesPieChartData = companyPlayersActivitiesPieChartData.filter(data => data.player_department === department);
	}

	for (let index = 0; index < companyPlayersActivitiesPieChartData.length; index += 1) {
		totalNumberOfSolvedTrivialChallenges += companyPlayersActivitiesPieChartData[index].total_number_of_solved_trivial_challenges
		totalNumberOfSolvedEasyChallenges += companyPlayersActivitiesPieChartData[index].total_number_of_solved_easy_challenges
		totalNumberOfSolvedMediumChallenges += companyPlayersActivitiesPieChartData[index].total_number_of_solved_medium_challenges
		totalNumberOfSolvedHardChallenges += companyPlayersActivitiesPieChartData[index].total_number_of_solved_hard_challenges
	}

	const totalSolvedChallenges = totalNumberOfSolvedTrivialChallenges + totalNumberOfSolvedEasyChallenges + totalNumberOfSolvedMediumChallenges + totalNumberOfSolvedHardChallenges
	const totalNumberOfSolvedTrivialChallengesPercentage = Math.round((totalNumberOfSolvedTrivialChallenges / totalSolvedChallenges) * 100)
	const totalNumberOfSolvedEasyChallengesPercentage = Math.round((totalNumberOfSolvedEasyChallenges / totalSolvedChallenges) * 100)
	const totalNumberOfSolvedMediumChallengesPercentage = Math.round((totalNumberOfSolvedMediumChallenges / totalSolvedChallenges) * 100)
	const totalNumberOfSolvedHardChallengesPercentage = Math.round((totalNumberOfSolvedHardChallenges / totalSolvedChallenges) * 100)

	// make percentage
	return {
		labels: ['Expert', 'Intermediate', 'Beginner', 'Novice'],
		datasets: [
			{
				data: [totalNumberOfSolvedHardChallengesPercentage, totalNumberOfSolvedMediumChallengesPercentage, totalNumberOfSolvedEasyChallengesPercentage, totalNumberOfSolvedTrivialChallengesPercentage],
				backgroundColor: [
					'rgba(241, 94, 90, 1)',
					'rgba(253, 171, 90, 1)',
					'rgba(181, 217, 157, 1)',
					'rgba(84, 193, 226, 1)',
				],
				borderColor: [
					'rgba(241, 94, 90, 1)',
					'rgba(253, 171, 90, 1)',
					'rgba(181, 217, 157, 1)',
					'rgba(84, 193, 226, 1)',
				],
				borderWidth: 1,
			},
		],
	}
}

function CompanyPlayersActivitiesPieChart({ companyPlayersActivitiesPieChartData, department }: CompanyPlayersActivitiesPieChartProps) {
	const pieChartInfo = getPieChartInfo(companyPlayersActivitiesPieChartData, department)
	return <Pie options={getOptions()} data={pieChartInfo} width={350} height={350} />
}
export default CompanyPlayersActivitiesPieChart
