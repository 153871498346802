import { Info, ArrowForward } from '@mui/icons-material'
import { Typography, Card, CardContent, Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip from '../../../../shared/components/Tooltip/Tooltip'

export const MediaCard = styled(Card)(() => ({
    position: 'relative',
    borderRadius: 8,
    transition: `0.3s`,
    boxShadow:
        'rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px',
    filter: `none`,
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

export const MediaCardName = styled(Typography)(() => ({
    fontSize: '18px',
    textAlign: 'center',
    position: 'relative',
    display: 'inline-block'
}))

export const MediaCardNameContainer = styled(Box)(() => ({
    textAlign: 'center',
}))

export const CompanyComparativeGradeCardWrapper = styled(Grid)(() => ({
    position: 'relative',
    transformStyle: 'preserve-3d',
    boxSizing: 'border-box',
    width: '100%',
    '&.flipped': {
        '& .companyComparativeGradeFrontCard': {
            transform: 'rotateY(180deg)',
        },
        '& .companyComparativeGradeBackCard': {
            transform: 'rotateY(0deg)'
        }
    },
    '& .companyComparativeGradeFrontCard': {
        position: 'absolute',
        backfaceVisibility: 'hidden',
        transformStyle: 'preserve-3d',
        transition: 'transform ease 500ms',
        top: '-64px',
        width: '260px',
        right: '-130px',
        zIndex: 2,
        transform: 'rotateY(0deg)',
    },
    '& .companyComparativeGradeBackCard': {
        position: 'absolute',
        backfaceVisibility: 'hidden',
        transformStyle: 'preserve-3d',
        transition: 'transform ease 500ms',
        top: '-64px',
        width: '260px',
        right: '-130px',
        transform: 'rotateY(-180deg)'
    },
}))

export const CompanyComparativeGradeCard = styled(Box)(() => ({
    position: 'relative'
}))

export const CustomArrowForward = styled(ArrowForward)(({ theme }) => ({
    position: 'absolute',
    right: '-14%',
    top: '50px',
    cursor: 'pointer',
    color: '#898585',
    [theme.breakpoints.between('md', "mdm")]: {
        right: '0',
    },
    [theme.breakpoints.between('sm', 'md')]: {
        right: '-50%',
    },
    [theme.breakpoints.down('sm')]: {
        right: '0',
    },
}));

export const GradeToolTip = styled(Tooltip)(() => ({
    top: 0,
    color: 'black !Important'
}))

export const MediaCardContent = styled(CardContent)({
    padding: '0',
    position: 'relative',
    paddingBottom: '0px !important'
})

export const MediaCardBody = styled(Typography)(() => ({
    fontSize: '60px',
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: '45px',
    margin: '25px 0',
}))

export const CompanyEfficiencyLevel = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'center',
    marginTop: '-20px',
    color: '#898585'

}))

export const HelpIcon = styled(Info)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '20px',
    position: 'absolute',
    top: '6px',
    right: '-24px',
    cursor: 'pointer'
}));
