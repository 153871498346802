import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BattleActivityType } from '../enums';

const initialState: { battleActivities: BattleActivityInfo[], gameSlug: string, challengeSlug: string }[] = []
const battleActivityTableSlice = createSlice({
    name: 'BattleActivityTableSlice',
    initialState,
    reducers: {
        addBattleActivityToTheList: (state: { battleActivities: BattleActivityInfo[], gameSlug: string, challengeSlug: string }[], action: PayloadAction<{ battleActivity: BattleActivityInfo, gameSlug: string, challengeSlug: string }>) => {
            const findBattleActivityList = state.find((battleActivityList) =>
                battleActivityList.gameSlug === action.payload.gameSlug &&
                battleActivityList.challengeSlug === action.payload.challengeSlug)
            if (findBattleActivityList) {
                if (findBattleActivityList.battleActivities.length >= 10) {
                    findBattleActivityList.battleActivities.pop()
                }
                findBattleActivityList.battleActivities.unshift(action.payload.battleActivity)
            }
            else {
                state.push({ battleActivities: [action.payload.battleActivity], gameSlug: action.payload.gameSlug, challengeSlug: action.payload.challengeSlug })
            }
            return state
        },
        addBattleActivityList: (state: { battleActivities: BattleActivityInfo[], gameSlug: string, challengeSlug: string }[], action: PayloadAction<{ battleActivities: BattleActivityInfoFromAPI[], gameSlug: string, challengeSlug: string }>) => {
            // eslint-disable-next-line no-param-reassign
            state = state.filter((battleActivityList) =>
                battleActivityList.gameSlug !== action.payload.gameSlug &&
                battleActivityList.challengeSlug !== action.payload.challengeSlug)
            const battleActivities: BattleActivityInfo[] = []
            // the following foreach loop organizes the backend response data following the BattleActivityInfo interface structure
            action.payload.battleActivities.forEach((battleActivity: BattleActivityInfoFromAPI) => {
                if (battleActivity.battle_info.battle_activity_type === BattleActivityType.Deployed) {
                    battleActivities.push(
                        {
                            playerUsername: battleActivity.battle_info.published_app_username,
                            targetPlayerUsername: "",
                            activityDate: battleActivity.battle_info.published_app_at,
                            battleActivityType: BattleActivityType.Deployed
                        }
                    )
                }
                else if (battleActivity.battle_info.battle_activity_type === BattleActivityType.Hacked) {

                    // create activities for all possible hackers entry, type should be hacked
                    battleActivity.battle_info.hacker_details.forEach((hackerDetails: HackerDetails) => {
                        battleActivities.push({
                            playerUsername: hackerDetails.hacked_app_player_username,
                            targetPlayerUsername: battleActivity.battle_info.published_app_username,
                            activityDate: hackerDetails.hacked_app_at,
                            battleActivityType: BattleActivityType.Hacked,
                        })
                    })
                    // for target user entry, type should be deployed
                    battleActivities.push({
                        playerUsername: battleActivity.battle_info.published_app_username,
                        targetPlayerUsername: "",
                        activityDate: battleActivity.battle_info.published_app_at,
                        battleActivityType: BattleActivityType.Deployed
                    })
                }
            });
            // sort (desc) the list based on activityDate
            battleActivities.sort((battleActivity1, battleActivity2) => {
                const battleActivity1Date = new Date(battleActivity1.activityDate)
                const battleActivity2Date = new Date(battleActivity2.activityDate)
                return (battleActivity2Date as any) - (battleActivity1Date as any)
            });

            // update list item whose battleActivityType is Deployed with either deployment or re-deployment 
            const updateBattleActivities: BattleActivityInfo[] = []
            battleActivities.reverse().forEach((battleActivity) => {
                if (battleActivity.battleActivityType === BattleActivityType.Deployed && updateBattleActivities.find(battleActivityItem => battleActivityItem.playerUsername === battleActivity.playerUsername)) {
                    updateBattleActivities.unshift({
                        playerUsername: battleActivity.playerUsername,
                        targetPlayerUsername: battleActivity.targetPlayerUsername,
                        activityDate: battleActivity.activityDate,
                        battleActivityType: BattleActivityType.Redeployed
                    })
                }
                else {
                    updateBattleActivities.unshift(battleActivity)
                }
            })
            state.push({ battleActivities: updateBattleActivities.slice(0, 10), gameSlug: action.payload.gameSlug, challengeSlug: action.payload.challengeSlug })
            return state
        }
    },
});
export const { addBattleActivityToTheList, addBattleActivityList } = battleActivityTableSlice.actions;
export default battleActivityTableSlice.reducer;

