/* eslint-disable no-nested-ternary */
import { Box } from '@mui/material'
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid'
import theme from '../../../../../../utils/theme'
import TableBox from './TableStyles'

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'Rank',
		align: 'center',
		headerAlign: 'center',
		flex: 75,
	},
	{ field: 'playerName', headerName: 'Player', flex: 150 },
	{
		field: 'score',
		headerName: 'Score',
		type: 'number',
		align: 'center',
		headerAlign: 'center',
		flex: 75,
	},
]


function Table({ className, top10Players }: TableProps) {
	const rows: { id: string; playerName: string; score: number }[] = []
	let player: string
	let rank: string
	for (let index = 0; index < top10Players?.length; index += 1) {
		player = top10Players[index].player__username
		rank = String(top10Players[index].rank!)

		if (top10Players[index].rank! === 1) rank = '🥇'
		if (top10Players[index].rank! === 2) rank = '🥈'
		if (top10Players[index].rank! === 3) rank = '🥉'

		rows.push({
			id: rank,
			playerName: player,
			score: top10Players[index].total_game_score,
		})
	}

	const onClickTableRow = (rowData: any) => {
		window.location.href = `${process.env.REACT_APP_SECDIM_ID_FRONTEND}${rowData.row.playerName}`
	}

	return (
		<TableBox className={className}>
			<Box width='100%'
				sx={{
					'& .cell-for-player-username': {
						color: theme.palette.primary.main,
					}
				}}
			>
				<DataGrid
					initialState={{
						pagination: { paginationModel: { page: 0, pageSize: 25 } },
					}}
					rows={rows}
					columns={columns}
					autoHeight
					pagination
					onRowClick={onClickTableRow}
					getCellClassName={(params: GridCellParams<any, any, number>) => {
						if (params.field === 'playerName') {
							return 'cell-for-player-username';
						}
						return '';
					}}
				/>
			</Box>
		</TableBox>
	)
}
export default Table
