import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import Challenge from '../../../assets/create_challenge.svg'
import Game from '../../../assets/create_game.svg'
import * as Styled from './CreateDialogStyles'
import { clearSelectedVulnerabilities } from '../../../redux/vulnerabilitiesSlice'

function CreateDialog({ isOpenDialog, handleCloseDialog }: DialogProps) {
    const dispatch = useDispatch()

    const navigateChallenge = () => {
		window.location.href = `${process.env.REACT_APP_SECDIM_PLAY_BUILD_LEVEL_GITHUB_REDIRECT}`
	}

    const navigateGame = () => {
        dispatch(clearSelectedVulnerabilities())
        window.location.href = `${process.env.REACT_APP_SECDIM_PLAY_FRONTEND}/create/game`
	}

	return (
		<Styled.DialogSpace id="DialogSpace" onClose={handleCloseDialog} open={isOpenDialog}>
			<Styled.AbsoluteClose onClick={handleCloseDialog} />
            <Styled.DialogContentGrid container>
                <Grid item md={6}>
                    <Styled.DialogContentWrapper hasDivider>
                        <Styled.DialogContent>
                            <Grid container direction="column">
                                <Grid item xs={4}>
                                    <Styled.ImageContainer>
                                        <Styled.DialogImage src={Game} alt="Failed to display dialog image" />
                                    </Styled.ImageContainer>
                                </Grid>
                                <Grid item xs={1}>
                                    <Styled.DialogTitleText variant='h3'>
                                        Create a Game 
                                    </Styled.DialogTitleText>
                                </Grid>
                                <Grid item xs={6}>
                                    <Styled.DialogTitleText>
                                        Create your own game with your own choice of challenges 🎮
                                    </Styled.DialogTitleText>
                                </Grid>
                                <Grid item xs={1}>
                                <Styled.ButtonWrapper>
                                    <Styled.CustomSecDimButton rounded onClick={navigateGame}>
                                        Create Game
                                    </Styled.CustomSecDimButton>
                                </Styled.ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Styled.DialogContent>
                    </Styled.DialogContentWrapper>
                </Grid>
                <Grid item md={6}>
                    <Styled.DialogContentWrapper>
                        <Styled.DialogContent>
                            <Grid container direction="column">
                                <Grid item xs={4}>
                                    <Styled.ImageContainer>
                                        <Styled.DialogImage src={Challenge} alt="Failed to display dialog image" />
                                    </Styled.ImageContainer>
                                </Grid>
                                <Grid item xs={1}>
                                    <Styled.DialogTitleText variant='h3'>
                                        Create a Challenge 
                                    </Styled.DialogTitleText>
                                </Grid>
                                <Grid item xs={6}>
                                    <Styled.DialogTitleText>
                                        Build a challenge and get a reward (bonus score, discounts, etc) 🎁
                                    </Styled.DialogTitleText>
                                </Grid>
                                <Grid item xs={1}>
                                    <Styled.ButtonWrapper>
                                        <Styled.CustomSecDimButton rounded onClick={navigateChallenge}>
                                            Create Challenge
                                        </Styled.CustomSecDimButton>
                                    </Styled.ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Styled.DialogContent>
                    </Styled.DialogContentWrapper>
                </Grid>
            </Styled.DialogContentGrid>
		</Styled.DialogSpace >
	)
}
export default CreateDialog 

