import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = { forkURI: '' }
const forkURISlice = createSlice({
	name: 'ForkURI',
	initialState,
	reducers: {
		setForkURI: (state, action: PayloadAction<string>) => {
			state.forkURI = action.payload // eslint-disable-line no-param-reassign
			return state
		}
	},
});
export const { setForkURI } = forkURISlice.actions;
export default forkURISlice.reducer;
