import { Box, TextField, Container } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PageContainer = styled(Container)({
	flex: '1 0 auto',
	paddingBottom: 75,
})

export const StrangeFlex = styled(Box)({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
})

export const CopyBoxText = styled(TextField)({
	width: '100%',
	border: 'none',
})

export const MainContainer = styled(Box)<{ bgcolor: string }>(
	({ bgcolor }) => ({
		backgroundColor: bgcolor,
		height: '100%',
		width: '100%',
	})
)
