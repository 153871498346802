import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Drawer, MenuItem } from '@mui/material'
import { UserManager, UserManagerSettings } from 'oidc-client-ts'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OpenIDContext, handleSignIn } from '../../api/auth'
import playBackendRequestHandler from '../../api/playBackendRequestHandler'
import AccountBox from '../../assets/AccountBox.svg'
import { ReactComponent as SecDimLogo } from '../../assets/short-logo.svg'
import Tooltip from '../../shared/components/Tooltip/Tooltip'
import * as Styled from './HeaderStyles'
import SDKOpenDialog from './components/CreateDialog'

function Header({ className, notifs }: HeaderProps) {
	const navigate = useNavigate()
	const [accountIcon, setAccountIcon] = useState<SVGSVGElement | null>(null)
	const openAccountMenu = Boolean(accountIcon)
	const [menuOpen, setMenuOpen] = useState(false)
	const { authenticated, profile, refresh } = useContext(OpenIDContext)
	const [signInLoading, setSignInLoading] = useState(
		sessionStorage.getItem('silentLoginAlreadyAttempted') !== 'true',
	)
	const code = new URLSearchParams(window.location.search).get('code')
	const [isSDKDialogOpened, setIsSDKDialogOpened] = useState(false)

	const silentSignInReload = useCallback(async () => {
		refresh()
		setSignInLoading(false)
	}, [refresh])

	useEffect(() => {
		if (signInLoading) {
			playBackendRequestHandler('playUserInfo').then(async userInfoResp => {
				if (!userInfoResp.data.logged_in && !code && !authenticated) {
					// the following settings are for silent login
					const clientID = (await playBackendRequestHandler('openidclient')).data.oclient_id
					const userManagerSettings: UserManagerSettings = {
						authority: `${process.env.REACT_APP_DJANGO_SECDIM_ID}openid`,
						client_id: clientID, // need to get this in dev env somehow...
						redirect_uri: window.location.origin,
						post_logout_redirect_uri: `${process.env.REACT_APP_SECDIM_ID_FRONTEND}account/logout`,
						response_type: 'code',
						scope: 'openid email',
						filterProtocolClaims: true,
						loadUserInfo: true,
						silent_redirect_uri: window.location.origin,
						silentRequestTimeoutInSeconds: 5,
					}
					sessionStorage.setItem('silentLoginAlreadyAttempted', 'true')
					const userManager: UserManager = new UserManager(userManagerSettings)
					userManager
						.signinSilent()
						.then(() => {
							silentSignInReload()
						})
						.catch((error: Error) => {
							// eslint-disable-next-line
							console.error(error)
							silentSignInReload()
						})
				}
			})
		}
	}, [authenticated, code, signInLoading, silentSignInReload])

	const toggleMenu = () => {
		setMenuOpen(prevState => !prevState)
	}

	const closeMenu = () => {
		setMenuOpen(false)
	}

	// TODO: fix type
	const openUserNav = (e: any) => {
		setAccountIcon(e.currentTarget)
		refresh()
	}

	const closeUserNav = () => {
		setAccountIcon(null)
	}

	const handleLogout = () => {
		sessionStorage.clear()
		playBackendRequestHandler('logout').then(() => {
			window.location.href = `${process.env.REACT_APP_SECDIM_ID_FRONTEND}account/logout` // nosemgrep
		})
	}

	const navigateToCompanyDashboard = () => {
		navigate('/company/dashboard')
	}

	const onClickGlobalScore = () => {
		navigate('/hall-of-fame')
	}

	const onClickBuildSDKLevelButton = () => {
		setIsSDKDialogOpened(!isSDKDialogOpened)
	}

	return (
		// Todo: Revisit this tablet view looks strange on ipad
		<Styled.AppHeader className={className}>
			<SDKOpenDialog
				isOpenDialog={isSDKDialogOpened}
				handleCloseDialog={onClickBuildSDKLevelButton}
			/>
			{/* Logo and Mobile Menu */}
			<Styled.LeftContainer>
				<Styled.LogoWrapper
					onClick={() => {
						window.location.href = 'https://secdim.com'
					}}
				>
					<SecDimLogo />
				</Styled.LogoWrapper>
				<Styled.MenuFirstRedirectItem href='https://learn.secdim.com/'>
					Learn
				</Styled.MenuFirstRedirectItem>
				<Styled.MenuItem to='/'>Play</Styled.MenuItem>
				<Styled.MenuRedirectItem href='https://discuss.secdim.com/'>
					Discuss
				</Styled.MenuRedirectItem>
				<Styled.MenuRedirectItem href='https://secdim.com/workshop/'>
					Workshops
				</Styled.MenuRedirectItem>
				<Styled.CustomMenuIcon onClick={toggleMenu} />
			</Styled.LeftContainer>

			{/* The Mobile Drawer */}
			<Drawer open={menuOpen} onClose={closeMenu}>
				<Styled.DrawerInnerContainer>
					<Styled.DrawerOption href='https://learn.secdim.com/'>
						Learn
					</Styled.DrawerOption>
					<Styled.DrawerOptionLink to='/'>Play</Styled.DrawerOptionLink>
					<Styled.DrawerOption href='https://discuss.secdim.com/'>
						Discuss
					</Styled.DrawerOption>
					<Styled.DrawerOption href='https://secdim.com/workshop/'>
						Workshops
					</Styled.DrawerOption>
				</Styled.DrawerInnerContainer>
			</Drawer>

			{/* The Rest of the Header */}
			<Styled.UserPanel>
				<Box mt={0.2}>
					<Tooltip arrow title='Hall of fame'>
						<Styled.HallOfFameButton onClick={onClickGlobalScore}>
							<Styled.CustomEmojiEvents color='primary' />
						</Styled.HallOfFameButton>
					</Tooltip>
				</Box>
				{!authenticated && code && (
					<Styled.SignInLoadingButton variant='outlined' onClick={handleSignIn}>
						<LoadingButton
							id='signInLoadingIcon'
							component='span'
							loading
							sx={{ position: 'absolute', left: 0 }}
						/>
						Sign In
					</Styled.SignInLoadingButton>
				)}
				{!authenticated && !code && (
					<Styled.SignInButton variant='outlined' onClick={handleSignIn}>
						Sign In
					</Styled.SignInButton>
				)}
				{authenticated && (
					<Box mt={0.2}>
						<Tooltip arrow title='Create a challenge or a game'>
							<Styled.BuildButton onClick={onClickBuildSDKLevelButton}>
								<Styled.BuildIcon color='primary' />
							</Styled.BuildButton>
						</Tooltip>
					</Box>
				)}

				{authenticated && (
					<Styled.AccountWrapper>
						{!profile.profileImage.includes('DefaultImage') ? (
							<Styled.UserProfileImageHeader
								src={profile.profileImage.replace('localhost:8000', 'localhost:8001')}
								onClick={openUserNav}
								alt={profile.username}
							/>
						) : (
							<Styled.AccountIcon color='primary' onClick={openUserNav} />
						)}
						<Styled.CustomMenu
							anchorEl={accountIcon}
							open={openAccountMenu}
							onClose={closeUserNav}
						>
							<MenuItem
								sx={{
									cursor: 'pointer',
									backgroundColor: 'transparent',
									'&:hover': {
										backgroundColor: 'transparent',
									},
									'&.Mui-selected': {
										backgroundColor: 'transparent',
									},
									'&.Mui-focusVisible': {
										backgroundColor: 'transparent',
									},
								}}
							>
								<Box
									display='flex'
									flexDirection='column'
									justifyContent='center'
									alignItems='center'
									width='100%'
								>
									{!profile.profileImage.includes('DefaultImage') ? (
										<Styled.UserNavLink
											href={`${process.env.REACT_APP_SECDIM_ID_FRONTEND}${profile.username}`}
										>
											<Styled.UserProfileImage
												src={profile.profileImage.replace(
													'localhost:8000',
													'localhost:8001',
												)}
												alt={profile.username}
											/>
										</Styled.UserNavLink>
									) : (
										<Styled.UserNavLink
											href={`${process.env.REACT_APP_SECDIM_ID_FRONTEND}${profile.username}`}
										>
											<Styled.CustomPerson src={AccountBox} />
										</Styled.UserNavLink>
									)}
									{profile.firstName || profile.lastName ? (
										<Styled.UserFullName variant='h5'>
											{profile.firstName} {profile.lastName}
										</Styled.UserFullName>
									) : (
										<Box mt={0.5} />
									)}
									<Styled.Username variant='caption' textAlign='center'>
										{profile.username}
									</Styled.Username>
									<Styled.Subscription variant='caption' textAlign='center'>
										{profile.subscriptionType === '' ? 'Free' : profile.subscriptionType}{' '}
										Plan
									</Styled.Subscription>
								</Box>
							</MenuItem>
							<MenuItem>
								<Styled.UserNavLink
									href={`${process.env.REACT_APP_SECDIM_ID_FRONTEND}${profile.username}`}
								>
									<Styled.CustomAccountCircle />{' '}
									<Styled.UserNavLinkText>Your Profile</Styled.UserNavLinkText>
								</Styled.UserNavLink>
							</MenuItem>
							{profile.restrictions.hasViewCompanyDashboardPermission && (
								<MenuItem onClick={navigateToCompanyDashboard}>
									<Styled.CustomBusiness />{' '}
									<Styled.UserNavLinkText>Company Dashboard</Styled.UserNavLinkText>
								</MenuItem>
							)}
							<MenuItem>
								<Styled.UserNavLink
									href={`${process.env.REACT_APP_SECDIM_ID_FRONTEND}account/settings`}
								>
									<Styled.CustomSettings />{' '}
									<Styled.UserNavLinkText>Account Settings</Styled.UserNavLinkText>
								</Styled.UserNavLink>
							</MenuItem>
							<MenuItem>
								<Styled.UserNavLink onClick={onClickBuildSDKLevelButton}>
									<Styled.CustomBuild />{' '}
									<Styled.UserNavLinkText>Create</Styled.UserNavLinkText>
								</Styled.UserNavLink>
							</MenuItem>
							<MenuItem>
								<Styled.UserNavLink
									href={`${process.env.REACT_APP_SECDIM_PLAY_FEEDBACK_REDIRECT}`}
								>
									<Styled.CustomFeedback />{' '}
									<Styled.UserNavLinkText>Give Feedback</Styled.UserNavLinkText>
								</Styled.UserNavLink>
							</MenuItem>
							<MenuItem onClick={handleLogout}>
								<Styled.CustomLogout />{' '}
								<Styled.UserNavLinkText>Logout</Styled.UserNavLinkText>
							</MenuItem>
						</Styled.CustomMenu>
						{notifs > 0 && (
							<Styled.NotificationBox>
								<Styled.NotificationText>
									{notifs > 9 ? '9+' : notifs}
								</Styled.NotificationText>
							</Styled.NotificationBox>
						)}
					</Styled.AccountWrapper>
				)}
			</Styled.UserPanel>
		</Styled.AppHeader>
	)
}

export default Header
