import { FilterAlt } from '@mui/icons-material'
import { Button, FormControl, InputLabel } from '@mui/material'
import styled from 'styled-components'

export const FilterAltIcon = styled(FilterAlt)({
	verticalAlign: 'bottom',
	width: '18px',
	height: '18px',
	color: '#464343',
	'&: hover': {
		color: '#fff',
	},
})

export const CustomFilterButton = styled(Button)({
	padding: 0,
	minWidth: 0,
	background: 'none',
})

export const CustomInputLabel = styled(InputLabel)({
	top: '-8px',
	left: '-14px',
})

export const CustomFormControl = styled(FormControl)({
	minWidth: '200px',
	'& .css-x7hkua-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
		height: '45px !important',
	},
	'& #department-filter-select-id': {
		padding: '10px',
		marginRight: '20px',
	},
})
