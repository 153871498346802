import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChallengeHintDescriptionProps {
    hintDescription: HintDescription
    isHintDescriptionDialogShown: boolean
}
const initialState: ChallengeHintDescriptionProps = { hintDescription: { uuid: "", title: "", description: "", kind: "" }, isHintDescriptionDialogShown: false }
const challengeHintDescriptionSlice = createSlice({
    name: 'ChallengeHintDescriptionSlice',
    initialState,
    reducers: {
        setChallengeHintDescription: (state: ChallengeHintDescriptionProps, action: PayloadAction<ChallengeHintDescriptionProps>) => {
            state = action.payload // eslint-disable-line no-param-reassign
            return state
        },
        setChallengeHintDescriptionDialogPrompt: (state: ChallengeHintDescriptionProps, action: PayloadAction<boolean>) => {
            state.isHintDescriptionDialogShown = action.payload // eslint-disable-line no-param-reassign
            return state
        }
    },
});
export const { setChallengeHintDescription, setChallengeHintDescriptionDialogPrompt } = challengeHintDescriptionSlice.actions;
export default challengeHintDescriptionSlice.reducer;