import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusStateInNumber } from '../enums';

interface ReduxChallengeState {
	state: number,
	gameSlug: string,
	challengeSlug: string,
	isChallengeTimeout: boolean,
	isAutoDeployed: boolean,
	isAlertShown?: boolean
	alertMessage?: string
}

const initialState: ReduxChallengeState = { state: StatusStateInNumber.NotStarted, gameSlug: "", challengeSlug: "", isChallengeTimeout: false, isAutoDeployed: false, isAlertShown: false, alertMessage: "" }
const challengeStateSlice = createSlice({
	name: 'ChallengeState',
	initialState,
	reducers: {
		setChallengeState: (state: ReduxChallengeState, action: PayloadAction<ReduxChallengeState>) => {
			state = action.payload // eslint-disable-line no-param-reassign
			return state
		}
	},
});
export const { setChallengeState } = challengeStateSlice.actions;
export default challengeStateSlice.reducer;

