// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/averta-regular-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/averta-semibold-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/averta-bold-webfont.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Averta;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
}
@font-face {
  font-family: Averta;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}
@font-face {
  font-family: Averta;
  font-style: normal;
  font-display: swap;
  font-weight: bold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff");
}`, "",{"version":3,"sources":["webpack://./src/assets/averta.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,2DACE;AAAJ;AAGA;EACE,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,2DACE;AAFJ;AAKA;EACE,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,2DACE;AAJJ","sourcesContent":["@font-face {\n  font-family: Averta;\n  font-style: normal;\n  font-display: swap;\n  font-weight: 400;\n  src: \n    url(\"./fonts/averta-regular-webfont.woff\") format('woff')\n  ;\n}\n@font-face {\n  font-family: Averta;\n  font-style: normal;\n  font-display: swap;\n  font-weight: 600;\n  src: \n    url(\"./fonts/averta-semibold-webfont.woff\") format('woff')\n  ;\n}\n@font-face {\n  font-family: Averta;\n  font-style: normal;\n  font-display: swap;\n  font-weight: bold;\n  src: \n    url(\"./fonts/averta-bold-webfont.woff\") format('woff')\n  ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
