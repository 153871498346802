/* eslint-disable camelcase */
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePlayerTotalScore } from '../../api/hooks'
import ErrorPage from '../../shared/components/ErrorPage/ErrorPage'
import Loader from '../../shared/components/Loader/Loader'
import Table from '../Game/components/LeaderBoard/components/Table/Table'
import * as Styled from './HallOfFameStyles'

function HallOfFame({ className }: DefaultProps) {
	const isMobile = useMediaQuery('(max-width:500px)')
	const { isLoading, data, isError } = usePlayerTotalScore()
	const location = useLocation()
	let top3Players: any = []

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location])

	if (data?.data && (data?.data as any).length > 0) {
		top3Players = data?.data as any
		if (isMobile) {
			top3Players = (data?.data as any).slice(0, 3) // if it is mobile then blindly take first 3 players
		} else if (top3Players.length >= 3) {
			top3Players = [
				(data?.data as any)[1],
				(data?.data as any)[0],
				(data?.data as any)[2],
			] // if it is not mobile then take first 3 players and place first player in the middle
		}

		if (top3Players.length === 2) {
			// in case top3Players length is equal 2, then make the third player blank
			top3Players.push({ player__username: '-', total_game_score: '-' })
		} else if (top3Players.length === 1) {
			// in case top3Players length is equal 1, then make the second and third player blank
			if (isMobile)
				top3Players = [
					(data?.data as any)[0],
					{ player__username: '-', total_game_score: '-' },
					{ player__username: '-', total_game_score: '-' },
				]
			else
				top3Players = [
					{ player__username: '-', total_game_score: '-' },
					(data?.data as any)[0],
					{ player__username: '-', total_game_score: '-' },
				]
		}
	}

	const onClickTop3PlayerCard = (username: string) => {
		window.location.href = `${process.env.REACT_APP_SECDIM_ID_FRONTEND}${username}`
	}

	return (
		<div className={className}>
			{isLoading ? (
				<Loader />
			) : (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>
					{isError && top3Players.length > 0 ? (
						<ErrorPage
							errorIconName='VideogameAssetOff'
							errorTitle='No Score'
							errorMessage='An error occurred. Refresh the page or contact support.'
						/>
					) : (
						<Box>
							<Box mt={3}>
								<Grid container>
									<Grid item xs>
										<Box>
											{/* <BreadcrumbsComponent items={breadcrumbs} /> */}
											<Typography
												mt={1}
												mb={2}
												variant='h1'
												textAlign='center'
												sx={{ fontSize: '2.5rem' }}
											>
												Hall of Fame
											</Typography>
											<Box mt={3} />
										</Box>
									</Grid>
								</Grid>
							</Box>
							<Styled.Top3PlayerCardWrapper container spacing={3}>
								{top3Players.map((item: any, index: number) => (
									<Styled.CustomCard
										item
										index={index}
										// eslint-disable-next-line react/no-array-index-key
										key={index}
										device={isMobile}
										onClick={() => onClickTop3PlayerCard(item.player__username)}
									>
										{isMobile ? (
											<Styled.RankMedal>
												{
													// eslint-disable-next-line no-nested-ternary
													index === 0 ? '🥇' : index === 1 ? '🥈' : '🥉'
												}
											</Styled.RankMedal>
										) : (
											<Styled.RankMedal>
												{
													// eslint-disable-next-line no-nested-ternary
													index === 0 ? '🥈' : index === 1 ? '🥇' : '🥉'
												}
											</Styled.RankMedal>
										)}
										<Styled.CustomCardContent>
											<Styled.CustomCardContentTitle gutterBottom variant='h5'>
												{item.player__username}
											</Styled.CustomCardContentTitle>
											<Typography
												variant='body2'
												color='text.secondary'
												sx={{ fontSize: '15px' }}
											>
												Score: {item.total_game_score}
											</Typography>
										</Styled.CustomCardContent>
									</Styled.CustomCard>
								))}
							</Styled.Top3PlayerCardWrapper>
							<Box mt={3}>
								<Table
									top10Players={
										// first add a "rank" property for each data item of the list (data.data)
										// then slice that list from 3rd index item to last item (max is 10)
										(data?.data as any)
											.map((item: any, index: number) => ({ rank: index + 1, ...item }))
											.slice(3, (data?.data as any).length)
									}
								/>
							</Box>
						</Box>
					)}
				</>
			)}
		</div>
	)
}
export default HallOfFame
