import { Box, Typography, Button, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Close } from '@mui/icons-material'

export const AbsoluteClose = styled(Close)({
    position: 'absolute',
    top: 20,
    right: 20,
    borderRadius: 10,
    cursor: 'pointer',
})

export const DialogInnerWrapper = styled(Box)(({ theme }) => ({
    width: 550,
    height: 608,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,

    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        height: 'auto',
    },
}))

export const SshSetUpLink = styled('a')(props => ({
    margin: '7px 3px 0px 3px',
    cursor: 'pointer',
    lineHeight: 1,
    color: props.theme.palette.primary.main,
    '&:hover': {
        textDecoration: 'underline',
    },
}))

export const Border = styled(Box)({
    border: '1px solid #d3cccc',
    borderRadius: '50%',
    margin: '25px 25px 20px 25px'
})

export const CopyBox = styled(Box)({
    backgroundColor: '#F1F1F1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '1rem',
    borderRadius: '20px 0 0 20px',
    cursor: 'pointer',
})

export const CopyBoxText = styled(TextField)({
    width: '100%',
    border: 'none',
})

export const CopyBtn = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 1rem',
    color: `${theme.palette.common.white} !important`,
    borderRadius: '0 20px 20px 0',
    cursor: 'pointer',
}))

export const StrongText = styled('strong')(({ theme }) => ({
    color: theme.palette.primary.main,
}))

export const CopiedText = styled(Typography)({
    padding: 16,
})

export const CopyBoxWrapper = styled(Box)({
    display: 'flex',
    margin: '20px 0px'
})

export const IDEWrapper = styled(Box)(({ theme }) => (
    {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '35px',

        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
    }
))

export const IdeHeader = styled(Typography)({
    fontWeight: 'bold',
    textAlign: 'center'
})

export const VSCodeWrapper = styled(Box)(({ theme }) => ({
    float: 'left',
    margin: '0px 15px 0px 0px',
    [theme.breakpoints.down('sm')]: {
        float: 'none',
        margin: 0,
    },
}))

export const IntelliJWrapper = styled(Box)(({ theme }) => ({
    margin: '0px 0px 0px 15px',
    [theme.breakpoints.down('sm')]: {
        margin: 0,
    },
}))


export const VSCodeButton = styled(Button)(({ theme }) => ({
    width: 220,
    padding: 12,
    borderColor: theme.palette.primary.main,
    fontWeight: 600,
    letterSpacing: '0.875px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 0,
    },

    '& > img': {
        float: 'left',
        height: '25px',
        width: '25px',
        marginRight: '10px'
    }
}))

export const IntelliJButton = styled(Button)(({ theme }) => ({
    width: 220,
    padding: 12,
    borderColor: theme.palette.primary.main,
    fontWeight: 600,
    letterSpacing: '0.875px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 0,
        marginTop: '20px'
    },

    '& > img': {
        float: 'left',
        height: '25px',
        width: '25px',
        marginRight: '10px'
    }
}))