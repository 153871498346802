/* eslint-disable no-nested-ternary */
import { Person, Group, FolderCopy, Business } from "@mui/icons-material"
import querystring from 'querystring'
import { ChallengeType, GameMode, Severity, TimePeriod, VulnerabilityFeatureCategory, WorkspaceStatus } from "../../enums"

export const getMonthFromIndex = (index: number) => {
    if (index === 0)
        return "January"
    if (index === 1)
        return "February"
    if (index === 2)
        return "March"
    if (index === 3)
        return "April"
    if (index === 4)
        return "May"
    if (index === 5)
        return "June"
    if (index === 6)
        return "July"
    if (index === 7)
        return "August"
    if (index === 8)
        return "September"
    if (index === 9)
        return "October"
    if (index === 10)
        return "November"
    return "December"
}

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const getGradeAndEfficiencyLevel = (zScore: number, percentileRank: number) => {
    if (zScore >= 2.0 && percentileRank >= 95)
        return {
            "grade": "A+",
            "efficiencyLevel": "Superior (top 5%)"
        }
    if (zScore >= 1.5 && percentileRank >= 90)
        return {
            "grade": "A",
            "efficiencyLevel": "Excellent (top 10%)"
        }
    if (zScore >= 1.0 && percentileRank >= 80)
        return {
            "grade": "A-",
            "efficiencyLevel": "Great (top 20%)"
        }
    if (zScore >= 0.5 && percentileRank >= 70)
        return {
            "grade": "B+",
            "efficiencyLevel": "Very Good (top 30%)"
        }
    if (zScore >= 0.0 && percentileRank >= 60)
        return {
            "grade": "B",
            "efficiencyLevel": "Good (top 40%)"
        }
    if (zScore >= -0.5 && percentileRank >= 50)
        return {
            "grade": "B-",
            "efficiencyLevel": "Above Average"
        }
    if (zScore >= -1.0 && percentileRank >= 40)
        return {
            "grade": "C+",
            "efficiencyLevel": "Average"
        }
    if (zScore >= -1.5 && percentileRank >= 30)
        return {
            "grade": "C",
            "efficiencyLevel": "Below Average"
        }
    if (zScore >= -2.0 && percentileRank >= 20)
        return {
            "grade": "C-",
            "efficiencyLevel": "Poor (below 20%)"
        }
    return {
        "grade": "F",
        "efficiencyLevel": "Very Poor (below 10%)"
    }
}

export const getStartDateAndEndDateBasedOnTimePeriod = (timePeriod: string) => {
    const startDate = new Date()
    if (timePeriod === TimePeriod.Last7Days) {
        startDate.setDate(startDate.getDate() - 7);
    }
    else if (timePeriod === TimePeriod.Last30Days) {
        startDate.setDate(startDate.getDate() - 30);
    }
    else if (timePeriod === TimePeriod.Last90Days) {
        startDate.setDate(startDate.getDate() - 90);
    }
    else if (timePeriod === TimePeriod.Last6Months) {
        startDate.setDate(startDate.getDate() - 180);
    }
    else if (timePeriod === TimePeriod.Last1Year) {
        startDate.setDate(startDate.getDate() - 365);
    }
    return {
        "startDate": startDate.getTime(),
        "endDate": (new Date()).getTime()
    }
}

export const convertTimestampToDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return (
        `${date.toLocaleString('en-US', { weekday: 'long' })
        } ${date.toLocaleString('en-US', { month: 'short' })
        } ${date.getDate()
        }, ${date.getFullYear()}`
    );
};

export const arrayRange = (start: number, stop: number, step: number) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );

export const getStatusStateInText = (status: number) => {
    if (status === 1)
        return "Running"
    if (status === 2)
        return "Failed"
    if (status === 3)
        return "Passed"
    if (status === 4)
        return "Hacked"
    if (status === 5)
        return "Pending"
    return "N/A"
}

export const getWorkspaceCreateProgressPercentageOfNextMilestone = (status: string) => {
    if (status === WorkspaceStatus.Pending)
        return 10
    if (status === WorkspaceStatus.Creating)
        return 25
    if (status === WorkspaceStatus.Created)
        return 50
    if (status === WorkspaceStatus.Starting)
        return 75
    if (status === WorkspaceStatus.Started)
        return 99
    if (status === WorkspaceStatus.Ready)
        return 100
    return 0
}

export const getGameTypeDetails = (gameType: GameMode) => {
    if (gameType === GameMode.Company)
        return {
            name: "Company",
            subTitle: "Your Company Exclusive Games",
            gameMode: GameMode.Company,
            icon: <Business />
        }
    if (gameType === GameMode.Single)
        return {
            name: "Secure Coding",
            subTitle: "Fix Security Bugs and Get a Score",
            gameMode: GameMode.Single,
            icon: <Person />
        }
    if (gameType === GameMode.Multi)
        return {
            name: "Attack & Defence",
            subTitle: "Defend Your App and Hack Others to Get a Score",
            gameMode: GameMode.Multi,
            icon: <Group />
        }
    return {
        name: "Browse",
        subTitle: "Search Challenges by Vulnerability",
        gameMode: GameMode.Catalog,
        icon: <FolderCopy />
    }
}

const addOrRemoveValueFromFeatureQueryParam = (vulnerabilityFeatureQueryObject: any, paramValue: string, paramType: string, isAppendNeeded: boolean) => {
    let existingParamValue = (new URLSearchParams(window.location.search)).getAll(paramType.toLowerCase());
    if (isAppendNeeded) {
        // this is for search category
        if (paramType === VulnerabilityFeatureCategory.Search) {
            if (paramValue) {
                // eslint-disable-next-line no-param-reassign
                vulnerabilityFeatureQueryObject[`${paramType.toLowerCase()}`] = paramValue
            }
            else {
                // eslint-disable-next-line no-param-reassign
                delete vulnerabilityFeatureQueryObject[`${paramType.toLowerCase()}`]
            }
        }
        // the following are for other categories
        else {
            if (existingParamValue.indexOf(paramValue) === -1) { // paramValue does not exist, then append
                existingParamValue = [...existingParamValue, paramValue]
            }
            else {
                // paramValue already exists, that means user deselect the feature item, and we can remove that item
                existingParamValue = existingParamValue.filter((item: string) => item !== paramValue)
            }
            if (existingParamValue.length > 0) {
                // eslint-disable-next-line no-param-reassign
                vulnerabilityFeatureQueryObject[`${paramType.toLowerCase()}`] = existingParamValue
            }
            else {
                // eslint-disable-next-line no-param-reassign
                delete vulnerabilityFeatureQueryObject[`${paramType.toLowerCase()}`]
            }
        }
        return vulnerabilityFeatureQueryObject
    }
    if (existingParamValue.length > 0) {
        // eslint-disable-next-line no-param-reassign
        vulnerabilityFeatureQueryObject[`${paramType.toLowerCase()}`] = existingParamValue
        return vulnerabilityFeatureQueryObject
    }
    return vulnerabilityFeatureQueryObject
}

export const getUpdatedPathWithAllSelectedFeatures = (paramValue: string, paramType: string) => {
    let vulnerabilityFeatureQueryObject = {}
    vulnerabilityFeatureQueryObject = addOrRemoveValueFromFeatureQueryParam(vulnerabilityFeatureQueryObject, paramValue, VulnerabilityFeatureCategory.Search, paramType === VulnerabilityFeatureCategory.Search)
    vulnerabilityFeatureQueryObject = addOrRemoveValueFromFeatureQueryParam(vulnerabilityFeatureQueryObject, paramValue, VulnerabilityFeatureCategory.Language, paramType === VulnerabilityFeatureCategory.Language)
    vulnerabilityFeatureQueryObject = addOrRemoveValueFromFeatureQueryParam(vulnerabilityFeatureQueryObject, paramValue, VulnerabilityFeatureCategory.Technology, paramType === VulnerabilityFeatureCategory.Technology)
    vulnerabilityFeatureQueryObject = addOrRemoveValueFromFeatureQueryParam(vulnerabilityFeatureQueryObject, paramValue, VulnerabilityFeatureCategory.OWASP, paramType === VulnerabilityFeatureCategory.OWASP)
    vulnerabilityFeatureQueryObject = addOrRemoveValueFromFeatureQueryParam(vulnerabilityFeatureQueryObject, paramValue, VulnerabilityFeatureCategory.CWE, paramType === VulnerabilityFeatureCategory.CWE)
    vulnerabilityFeatureQueryObject = addOrRemoveValueFromFeatureQueryParam(vulnerabilityFeatureQueryObject, paramValue, VulnerabilityFeatureCategory.Severity, paramType === VulnerabilityFeatureCategory.Severity)
    vulnerabilityFeatureQueryObject = addOrRemoveValueFromFeatureQueryParam(vulnerabilityFeatureQueryObject, paramValue, VulnerabilityFeatureCategory.Likelihood, paramType === VulnerabilityFeatureCategory.Likelihood)
    vulnerabilityFeatureQueryObject = addOrRemoveValueFromFeatureQueryParam(vulnerabilityFeatureQueryObject, paramValue, VulnerabilityFeatureCategory.Impact, paramType === VulnerabilityFeatureCategory.Impact)

    return querystring.stringify(vulnerabilityFeatureQueryObject)
}

export const checkUrlParamsIfAnyFeatureExists = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('language') || params.get('technology') || params.get('owasp') || params.get('cwe') || params.get('severity') || params.get('likelihood') || params.get('impact')
}

export const isBattleChallenge = (challenge: Challenge) => challenge.tags.find((tag: Tag) => tag.name.toLowerCase() === ChallengeType.Battle.toLowerCase())

export const isLiveBattleChallenge = (challenge: Challenge) => isBattleChallenge(challenge) && challenge.tags.find((tag: Tag) => tag.name.toLowerCase() === ChallengeType.Live.toLowerCase())

export const updateQueryParamsBasedOnLatestFeatureList = (vulnerabilitiesSearchFeatureInfo: VulnerabilitiesSearchFeatureInfo[]) => {
    const vulnerabilityFeatureQueryObject: any = {}
    // check search key param, if it exists then add that in vulnerabilityFeatureQueryObject before checking all other keys like lang, tech etc.
    const existingSearchParamValue = (new URLSearchParams(window.location.search)).get(VulnerabilityFeatureCategory.Search.toLowerCase());
    if (existingSearchParamValue) {
        vulnerabilityFeatureQueryObject.search = existingSearchParamValue
    }
    vulnerabilitiesSearchFeatureInfo.forEach((item: VulnerabilitiesSearchFeatureInfo) => {
        let existingParamValue = (new URLSearchParams(window.location.search)).getAll(item.vulnerabilityFeatureCategory.toLowerCase());
        if (existingParamValue.length > 0) {
            // overwrite existingParamValue list based on available feature list item to update the path
            existingParamValue = existingParamValue.filter((paramValue: string) =>
                item.vulnerabilityFeatureList.find((vulnerabilityFeature: VulnerabilityFeature) => vulnerabilityFeature.slug === paramValue)
            )
            if (existingParamValue.length > 0) {
                vulnerabilityFeatureQueryObject[`${item.vulnerabilityFeatureCategory.toLowerCase()}`] = existingParamValue
            }
        }
    })
    return querystring.stringify(vulnerabilityFeatureQueryObject)
}

export const getSeverityTagColor = (severity: string) => {
    if (severity.toLowerCase() === Severity.Informational.toLowerCase())
        return 'rgb(85, 193, 226)'
    if (severity.toLowerCase() === Severity.Low.toLowerCase())
        return 'rgb(6, 185, 13)'
    if (severity.toLowerCase() === Severity.Medium.toLowerCase())
        return 'rgb(255, 147, 56)'
    if (severity.toLowerCase() === Severity.High.toLowerCase())
        return 'rgb(241, 94, 90)'
    if (severity.toLowerCase() === Severity.Critical.toLowerCase())
        return 'purple'
    return ''
}
