import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CDEOpenDialogPromptSliceProps {
    isShownLaunchDialog: boolean,
    isShownSubscriptionDialog: boolean,
    challengeId: string,
    vulnerabilitySlug: string,
    alertMessageForCDEOpenAttempt: {
        title: string,
        message: string,
    },
    numberOfPerformedSandboxAttempt: number
    numberOfFreeSandboxAttempt: number
}
const initialState: CDEOpenDialogPromptSliceProps = { isShownLaunchDialog: false, isShownSubscriptionDialog: false, challengeId: "", vulnerabilitySlug: "", alertMessageForCDEOpenAttempt: { title: "", message: "" }, numberOfPerformedSandboxAttempt: 0, numberOfFreeSandboxAttempt: 0 }
const CDEOpenDialogPromptSlice = createSlice({
    name: 'CDEOpenDialogPromptSlice',
    initialState,
    reducers: {
        setCDEOpenDialogPrompt: (state: CDEOpenDialogPromptSliceProps, action: PayloadAction<CDEOpenDialogPromptSliceProps>) => {
            // eslint-disable-next-line no-param-reassign
            state = action.payload
            return state
        },
    },
});
export const { setCDEOpenDialogPrompt } = CDEOpenDialogPromptSlice.actions;
export default CDEOpenDialogPromptSlice.reducer;