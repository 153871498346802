import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BattleActivityType } from '../enums';

const initialState: { publishedAppList: PublishedApp[], gameSlug: string, challengeSlug: string }[] | null = []
const publishedAppListBundleSlice = createSlice({
    name: 'PublishedAppList',
    initialState,
    reducers: {
        setPublishedAppListBundle: (state: { publishedAppList: PublishedApp[], gameSlug: string, challengeSlug: string }[], action: PayloadAction<{ publishedAppList: PublishedApp[], gameSlug: string, challengeSlug: string }>) => {
            const findPublishedAppList = state.find((publishedAppList) =>
                publishedAppList.gameSlug === action.payload.gameSlug &&
                publishedAppList.challengeSlug === action.payload.challengeSlug)
            if (findPublishedAppList) {
                findPublishedAppList.publishedAppList = action.payload.publishedAppList // overwrite existing item
            }
            else {
                state.push(action.payload) // pushed new one
            }
            return state
        },
        addOrUpdatePublishedAppToTheList: (state: { publishedAppList: PublishedApp[], gameSlug: string, challengeSlug: string }[], action: PayloadAction<{ battleIncident: BattleIncidentWSProps, loggedInPlayerUsername: string }>) => {
            // find published list from bundle
            const findPublishedAppList = state.find((publishedAppList) =>
                publishedAppList.gameSlug === action.payload.battleIncident.game_slug &&
                publishedAppList.challengeSlug === action.payload.battleIncident.challenge_slug)
            if (findPublishedAppList) {
                // find published app from published list
                const findPublishedApp = findPublishedAppList.publishedAppList.find(
                    (publishedApp) =>
                        publishedApp.published_app_player_username === action.payload.battleIncident.published_app.published_app_player_username
                )
                if (action.payload.battleIncident.event_type === BattleActivityType.Deployed ||
                    action.payload.battleIncident.event_type === BattleActivityType.Redeployed) {
                    if (findPublishedApp) {
                        // the following filter is to remove old app from redux store when app is deployed or redeployed
                        findPublishedAppList.publishedAppList = findPublishedAppList.publishedAppList.filter(
                            (publishedApp) =>
                                publishedApp.published_app_player_username !== action.payload.battleIncident.published_app.published_app_player_username
                        )
                    }
                    findPublishedAppList.publishedAppList.push(action.payload.battleIncident.published_app)
                }
                else if (action.payload.battleIncident.event_type === BattleActivityType.Hacked && findPublishedApp) {
                    // show trophy and green shadow only for the logged in player
                    if (action.payload.battleIncident.player_username === action.payload.loggedInPlayerUsername) {
                        findPublishedApp.did_you_hack_this_app = true
                    }
                    // find published app player from publishedApp list who hacked the app
                    const findAppPlayerWhoHacked = findPublishedAppList.publishedAppList.find(
                        (publishedApp) =>
                            publishedApp.published_app_player_username === action.payload.battleIncident.player_username
                    )
                    if (findAppPlayerWhoHacked) {
                        findAppPlayerWhoHacked.how_many_apps_this_app_publisher_hacked += 1 // inc by 1 in redux storage, if a player hacks an app
                    }
                    // update app card status (hack) to show skull to all player's battle page
                    findPublishedApp.is_app_already_hacked = true
                    findPublishedApp.attempts = action.payload.battleIncident.published_app.attempts
                }
                else if (action.payload.battleIncident.event_type === BattleActivityType.AttemptedToHack && findPublishedApp) {
                    findPublishedApp.attempts = action.payload.battleIncident.published_app.attempts
                }
            }
            return state
        },
    },
});
export const { setPublishedAppListBundle, addOrUpdatePublishedAppToTheList } = publishedAppListBundleSlice.actions;
export default publishedAppListBundleSlice.reducer;

