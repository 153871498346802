import { Fade } from '@mui/material'
import { lazy, useContext, useEffect, useRef, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'
import { handleSignIn, OpenIDContext } from '../../api/auth'
import { WebsocketContext } from '../../api/hooks'
import { GameMode } from '../../enums'
import CompanyDashboard from '../../pages/Company/CompanyDashboard'
import HallOfFame from '../../pages/HallOfFame/HallOfFame'
import NotFound from '../../pages/NotFound/NotFound'
import { getGameTypeDetails } from '../../shared/helpers/utils'
import Page from '../Page/Page'

const Landing = lazy(() => import('../../pages/Landing/Landing'))
const Challenge = lazy(() => import('../../pages/Challenge/Challenge'))
const ModifyGame = lazy(() => import('../../pages/ModifyGame/ModifyGame'))
const Game = lazy(() => import('../../pages/Game/Game'))
const Battle = lazy(() => import('../../pages/Battle/Battle'))
const Status = lazy(() => import('../../pages/Status/Status'))
const Vulnerabilities = lazy(
	() => import('../../pages/Vulnerabilities/Vulnerabilities'),
)
const Vulnerability = lazy(
	() => import('../../pages/Vulnerability/Vulnerability'),
)
const VulnerabilityStatus = lazy(
	() => import('../../pages/VulnerabilityStatus/VulnerabilityStatus'),
)
const AutoBattleDeployment = lazy(
	() => import('../../pages/AutoBattleDeployment/AutoBattleDeployment'),
)

function PlayRouter() {
	const wsClient = useRef<WebSocket | null>(null) // nosemgrep
	const { authenticated } = useContext(OpenIDContext)
	const [wSConnection, setWSConnection] = useState<{
		isWSConnectionEstablished: boolean
		wsConnectionMessage: string
	}>({ isWSConnectionEstablished: false, wsConnectionMessage: '' })

	useEffect(() => {
		if (authenticated) {
			if (!wsClient.current) {
				wsClient.current = new WebSocket(
					`${
						process.env.REACT_APP_BASE_API_URL?.includes('localhost') ? 'ws' : 'wss'
					}://${process.env
						.REACT_APP_BASE_API_URL!.replace('http://', '')
						.replace('https://', '')}ws/notifications/`,
				)
				wsClient.current.onopen = () => {
					setWSConnection({
						isWSConnectionEstablished: true,
						wsConnectionMessage: '',
					})
				}
				wsClient.current.onerror = () => {
					setWSConnection({
						isWSConnectionEstablished: false,
						wsConnectionMessage:
							'There was an error in establishing Web Socket. Please refresh the page. If the error persists, please check your network connection',
					})
				}
			}
		}
		else {
			const urlParams = new URLSearchParams(window.location.search);
			const origin = urlParams.get("origin");

			if (origin === "id") {
				urlParams.delete("origin");
				const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
				window.history.replaceState({}, document.title, newUrl);
				if(!authenticated) {
					handleSignIn();
				}
			}

		}
	}, [authenticated])

	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<WebsocketContext.Provider value={{ wsClient, ...wSConnection }}>
			<TransitionGroup className='full-height'>
				<Fade>
					<div className='full-height'>
						<Routes>
							<Route
								path='/company'
								element={
									<Page
										page={<Landing gameCategory={getGameTypeDetails(GameMode.Company)} />}
									/>
								}
							/>
							<Route
								path='/'
								element={<Page page={<Landing gameCategory={null} />} />}
							/>
							<Route
								path='/attack-defence'
								element={
									<Page
										page={<Landing gameCategory={getGameTypeDetails(GameMode.Multi)} />}
									/>
								}
							/>
							<Route
								path='/browse'
								element={
									<Page
										page={<Landing gameCategory={getGameTypeDetails(GameMode.Catalog)} />}
									/>
								}
							/>{' '}
							<Route path='/game/:gameSlug' element={<Page page={<Game />} />} />
							<Route path='/game/:gameSlug/edit' element={<Page page={<ModifyGame />} />} />
							<Route path='/create/game/' element={<Page page={<ModifyGame />} />} />
							<Route path='/hall-of-fame' element={<Page page={<HallOfFame />} />} />
							<Route
								path='/company/dashboard'
								element={<Page page={<CompanyDashboard />} />}
							/>
							<Route path='/wip' element={<Page page={<NotFound wip />} />} />
							<Route
								path='/game/:gameSlug/challenge/:challengeSlug'
								element={<Page page={<Challenge />} />}
							/>
							<Route
								path='/game/:gameSlug/challenge/:challengeSlug/status'
								element={<Page page={<Status />} />}
							/>
							<Route
								path='/game/:gameSlug/challenge/:challengeSlug/publish'
								element={<Page page={<AutoBattleDeployment />} />}
							/>
							<Route
								path='/game/:gameSlug/challenge/:challengeSlug/battle'
								element={<Page page={<Battle />} />}
							/>
							<Route path='/sandbox' element={<Page page={<Vulnerabilities />} />} />
							<Route
								path='/sandbox/:vulnerabilitySlug/id/:challengeId'
								element={<Page page={<Vulnerability />} />}
							/>
							<Route
								path='/sandbox/:vulnerabilitySlug/id/:challengeId/status'
								element={<Page page={<VulnerabilityStatus />} />}
							/>
							<Route path='*' element={<Page page={<NotFound />} />} />
						</Routes>
					</div>
				</Fade>
			</TransitionGroup>
		</WebsocketContext.Provider>
	)
}
export default PlayRouter
