import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Department } from '../../../../enums';
import * as Styled from './CompanyPlayersDepartmentFilterStyles';

function CompanyPlayersDepartmentFilter({ company, setSelectedDepartment, selectedDepartment }: CompanyPlayersDepartmentFilterProps) {
    const onClickDepartment = (event: SelectChangeEvent) => {
        setSelectedDepartment(event.target.value)
    }

    return <Styled.CustomFormControl>
        <Styled.CustomInputLabel id="department-filter-label">Department</Styled.CustomInputLabel>
        <Select
            labelId="department-filter-label"
            id="department-filter-select-id"
            value={selectedDepartment}
            onChange={onClickDepartment}
        >
            <MenuItem value={Department.Default} selected>
                {Department.Default}
            </MenuItem>
            {
                company?.departments.map((department) => (
                    <MenuItem key={department.slug} value={department.name}>
                        {department.name}
                    </MenuItem>
                ))
            }
        </Select>
    </Styled.CustomFormControl>
}

export default CompanyPlayersDepartmentFilter