import { Save } from '@mui/icons-material';
import { Alert, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { OpenIDContext } from '../../../../../../api/auth';
import playBackendRequestHandler from '../../../../../../api/playBackendRequestHandler';
import * as Styled from './CompanyPlayerDepartmentDialogStyles';

export default function CompanyPlayerDepartmentDialog({ openAssignDepartmentDialogInfo, handleCloseAssignDepartmentDialog, departments, refetchCompanyData }: CompanyPlayerDepartmentDialogProps) {
    const [selectedDepartment, setSelectedDepartment] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)
    const [assignDepartmentResponseMessage, setAssignDepartmentResponseMessage] = useState({ error: '', message: '' })
    const { profile } = useContext(OpenIDContext)

    const handleDepartmentAssignClick = () => {
        const users: { guid: string, department: string }[] = []
        users.push({ "guid": openAssignDepartmentDialogInfo.playerGuid, department: selectedDepartment })
        const data = {
            username: profile.guid,
            users
        };
        playBackendRequestHandler(
            'batchUserUpdate',
            data,
            undefined,
            undefined
        ).then((resp: any) => {
            setIsLoading(false)
            setSelectedDepartment("")
            setAssignDepartmentResponseMessage({ error: '', message: resp.data })
            refetchCompanyData.refetch()
        }).catch((err: any) => {
            setAssignDepartmentResponseMessage({ error: err.response.data.detail, message: '' })
            setIsLoading(false)
            setSelectedDepartment("")
        })
    }

    return (
        <Styled.DialogSpace id="DialogSpace" onClose={() => handleCloseAssignDepartmentDialog} open={openAssignDepartmentDialogInfo.isDialogShown}>
            <Styled.AbsoluteClose onClick={handleCloseAssignDepartmentDialog} />
            <Styled.DialogContentGrid container>
                <Styled.DialogContent>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Styled.DialogTitleText variant='h3'>
                                Assign Department
                            </Styled.DialogTitleText>
                        </Grid>
                        <Grid item xs={12} pb={5}>
                            <Styled.DialogTitleText>
                                Assign user to a department.
                            </Styled.DialogTitleText>
                        </Grid>
                        <Grid item xs={12} pb={5}>
                            <TextField select fullWidth
                                value={selectedDepartment}
                                label="Department"
                                onChange={(e: any) => {
                                    setSelectedDepartment(e.target.value)
                                }}
                            >
                                {departments.map(department => (
                                    <MenuItem key={department.slug} value={department.slug}>
                                        {department.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={1} pb={2.5}>
                            {isLoading ?
                                <Styled.CustomLoadingButton loading loadingPosition="start" startIcon={<Save />}>
                                    <Typography>Please Wait</Typography>
                                </Styled.CustomLoadingButton>
                                :
                                <Styled.AssignDepartmentButton onClick={() => handleDepartmentAssignClick()}>
                                    Assign Department
                                </Styled.AssignDepartmentButton>
                            }
                        </Grid>
                        <Grid item xs={1} >
                            {assignDepartmentResponseMessage.error !== "" ?
                                // eslint-disable-next-line react/jsx-no-undef
                                <Alert severity="error">
                                    {assignDepartmentResponseMessage.error}
                                </Alert>
                                :
                                null
                            }
                            {assignDepartmentResponseMessage.message !== "" ?
                                // eslint-disable-next-line react/jsx-no-undef
                                <Alert severity="success" sx={{ width: "100%" }}>
                                    User department assigned.
                                </Alert>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </Styled.DialogContent>
            </Styled.DialogContentGrid>
        </Styled.DialogSpace >
    );
}
