import { Grid, Typography, CardContent } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Top3PlayerCardWrapper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    margin: '0px auto',
    width: '100%',

    [theme.breakpoints.up('smm')]: {
        marginTop: '70px'
    },

    [theme.breakpoints.down('smm')]: {
        flexDirection: 'column'
    },
}))


export const CustomCard = styled(Grid, {
    shouldForwardProp: (prop) => prop !== "index" && prop !== "device"
})<{ index: number, device: any }>(
    ({ theme, index, device }) => ({
        margin: `${index === 1 ? '-140px' : '0px'} 10px 0px 10px`,
        textAlign: "center",
        padding: '0px !important',
        width: '20%',
        float: 'left',
        cursor: 'pointer',
        // eslint-disable-next-line no-nested-ternary
        boxShadow: index === 0 ? `0px 0px 5px 0px ${device ? '#f2aa00c2' : '#989898a3'}` :
            index === 1 ? `0px 0px 5px 0px ${device ? '#989898a3' : '#f2aa00c2'}` :
                '0px 0px 5px 0px #c67036a6',

        [theme.breakpoints.down('md')]: {
            width: '23%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '28%',
        },
        [theme.breakpoints.down('smm')]: {
            margin: '0px',
            width: '80%',
            marginBottom: '20px'
        },
    })
)

export const CustomCardContent = styled(CardContent)({
    paddingTop: '0px',
    paddingBottom: '8px !important'
})

export const CustomCardContentTitle = styled(Typography)({
    fontSize: '20px',
    marginBottom: '0px',
    wordWrap: 'break-word'
})

export const RankMedal = styled(Typography)({
    fontSize: '70px',
    lineHeight: '1.4'
})

