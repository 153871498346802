/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { VulnerabilityFeatureCategory } from '../enums'
import { checkUrlParamsIfAnyFeatureExists } from '../shared/helpers/utils'

const initialState: {
	vulnerabilities: Vulnerability[]
	filteredVulnerabilities: Vulnerability[]
	filteredSandboxes: SandBox[]
	selectedVulnerabilities: SandBox[]
	pageNumber: number
} = {
	vulnerabilities: [],
	filteredVulnerabilities: [],
	filteredSandboxes: [],
	selectedVulnerabilities: [],
	pageNumber: 1
}
const vulnerabilitiesSlice = createSlice({
	name: 'Vulnerabilities',
	initialState,
	reducers: {
		addVulnerabilities: (state, action: PayloadAction<Vulnerability[]>) => {
			// initially vulnerabilities and searchedVulnerabilities will be same
			state.vulnerabilities = action.payload // eslint-disable-line no-param-reassign
			return state
		},
		searchVulnerabilities: (
			state,
			action: PayloadAction<{ vulnerabilities: Vulnerability[] }>,
		) => {
			const filterSandboxesBasedOnSelectedFeatureList = (
				sandboxes: SandBox[],
				selectedFeatureList: string[],
				vulnerabilityFeatureCategory: string,
			) => {
				const filteredSandboxes: SandBox[] = []
				// check all sandboxes inside vulnerability
				sandboxes.forEach((sandbox: SandBox) => {
					let isFound = false
					// if feature category is language, then filter sandboxes based on language property compared with selectedFeatureList
					if (
						sandbox.language &&
						vulnerabilityFeatureCategory &&
						vulnerabilityFeatureCategory.toLowerCase() ===
							VulnerabilityFeatureCategory.Language.toLowerCase() &&
						selectedFeatureList.find(
							(item: string) => item.toLowerCase() === sandbox.language.toLowerCase(),
						)
					) {
						isFound = true
					}
					// if feature category is technology, then filter sandboxes based on sandbox tags list compared with selectedFeatureList
					else if (
						sandbox.technologies &&
						vulnerabilityFeatureCategory &&
						vulnerabilityFeatureCategory.toLowerCase() ===
							VulnerabilityFeatureCategory.Technology.toLowerCase() &&
						selectedFeatureList.find((item: string) =>
							sandbox.technologies.find(
								(technology: string) => item.toLowerCase() === technology.toLowerCase(),
							),
						)
					) {
						isFound = true
					}
					// if feature category is owasp, cwe or severity, then filter
					// first check if any language is selected or not, if selected, then filter sandboxes based on language as priority,
					// if language is not selected, then then filter sandboxes based on technology as second priority,
					// the above two cases will be applied on the vulnerabilities that are already filtered based on selected owasp, cwe or severity features
					else if (
						(vulnerabilityFeatureCategory &&
							vulnerabilityFeatureCategory.toLowerCase() ===
								VulnerabilityFeatureCategory.OWASP.toLowerCase()) ||
						vulnerabilityFeatureCategory.toLowerCase() ===
							VulnerabilityFeatureCategory.CWE.toLowerCase() ||
						vulnerabilityFeatureCategory.toLowerCase() ===
							VulnerabilityFeatureCategory.Severity.toLowerCase()
					) {
						// get all selected languages from the url language query param
						const selectedLanguageFeatureList = new URLSearchParams(
							window.location.search,
						).getAll(VulnerabilityFeatureCategory.Language.toLowerCase())
						if (
							sandbox.language &&
							selectedLanguageFeatureList.length > 0 &&
							selectedLanguageFeatureList.find(
								(item: string) => item.toLowerCase() === sandbox.language.toLowerCase(),
							)
						) {
							isFound = true
						} else if (selectedLanguageFeatureList.length === 0) {
							const selectedTechnologyFeatureList = new URLSearchParams(
								window.location.search,
							).getAll(VulnerabilityFeatureCategory.Technology.toLowerCase())
							if (
								selectedTechnologyFeatureList.length > 0 &&
								selectedTechnologyFeatureList.find((item: string) =>
									sandbox.technologies.find(
										(technology: string) =>
											item.toLowerCase() === technology.toLowerCase(),
									),
								)
							) {
								isFound = true
							} else if (selectedTechnologyFeatureList.length === 0) {
								isFound = true
							}
						}
					}

					// before pushing sandbox to filteredSandboxes array, first check, that sandbox is already pushed before just to avoid duplicate
					if (isFound) {
						filteredSandboxes.push(sandbox)
					}
				})
				return filteredSandboxes
			}

			const extractSandboxesFromVulnerabilityBasedOnSelectedFeature = (
				vulnerability: Vulnerability,
				selectedFeatureList: string[],
				vulnerabilityFeatureCategory: string,
			) => {
				let filteredSandboxes: SandBox[] =
					filterSandboxesBasedOnSelectedFeatureList(
						vulnerability.sandboxes,
						selectedFeatureList,
						vulnerabilityFeatureCategory,
					)
				// check all sandboxes inside vulnerability
				filteredSandboxes = filteredSandboxes.map((sandbox: SandBox) => ({
					...sandbox,
					vulnerabilityTitle: vulnerability.title,
					createdAt: vulnerability.created_at,
					vulnerabilitySlug: vulnerability.slug,
					description: vulnerability.description,
					severity: vulnerability.severity,
				}))
				return filteredSandboxes
			}

			const filterVulnerabilitiesBasedOnSelectedFeatureList = (
				vulnerabilities: Vulnerability[],
				selectedFeatureList: string[],
				vulnerabilityFeatureCategory: string,
			) => {
				// this filteredVulnerabilities contain vulnerabilities based on selected feature list
				const filteredVulnerabilities: Vulnerability[] = []
				// this filteredSandboxes contain sandboxes from filtered vulnerabilities
				const filteredSandboxes: SandBox[] = []
				vulnerabilities.forEach((vulnerability: Vulnerability) => {
					if (
						(vulnerabilityFeatureCategory &&
							vulnerabilityFeatureCategory.toLowerCase() ===
								VulnerabilityFeatureCategory.Language.toLowerCase()) ||
						vulnerabilityFeatureCategory.toLowerCase() ===
							VulnerabilityFeatureCategory.Technology.toLowerCase()
					) {
						const extractedSandBox =
							extractSandboxesFromVulnerabilityBasedOnSelectedFeature(
								vulnerability,
								selectedFeatureList,
								vulnerabilityFeatureCategory,
							)
						if (extractedSandBox.length > 0) {
							// if extractedSandBox is more than 0, it means, vulnerability contains search result
							filteredVulnerabilities.push(vulnerability)
							filteredSandboxes.push(...extractedSandBox)
						}
					} else if (
						vulnerabilityFeatureCategory &&
						vulnerabilityFeatureCategory.toLowerCase() ===
							VulnerabilityFeatureCategory.OWASP.toLowerCase()
					) {
						for (let i = 0; i < vulnerability.owasps.length; i += 1) {
							if (
								selectedFeatureList.find(
									(item: string) => item === vulnerability.owasps[i].slug,
								)
							) {
								// once this vulnerability is pushed, we can break the owasps loop, and we don't need to check further owasp, since we already found for one selected owasp
								// this is doing just to avoid duplicate vulnerability push for same vulnerability but different owasps
								filteredVulnerabilities.push(vulnerability)
								filteredSandboxes.push(
									...extractSandboxesFromVulnerabilityBasedOnSelectedFeature(
										vulnerability,
										selectedFeatureList,
										vulnerabilityFeatureCategory,
									),
								)
								break
							}
						}
					} else if (
						vulnerabilityFeatureCategory &&
						vulnerabilityFeatureCategory.toLowerCase() ===
							VulnerabilityFeatureCategory.CWE.toLowerCase()
					) {
						for (let i = 0; i < vulnerability.cwes.length; i += 1) {
							if (
								selectedFeatureList.find(
									(item: string) => item === vulnerability.cwes[i].slug,
								)
							) {
								// once this vulnerability is pushed, we can break the cwes loop, and we don't need to check further cwe, since we already found for one selected cwe
								// this is doing just to avoid duplicate vulnerability push for same vulnerability but different cwes
								filteredVulnerabilities.push(vulnerability)
								filteredSandboxes.push(
									...extractSandboxesFromVulnerabilityBasedOnSelectedFeature(
										vulnerability,
										selectedFeatureList,
										vulnerabilityFeatureCategory,
									),
								)
								break
							}
						}
					} else if (
						vulnerabilityFeatureCategory &&
						vulnerabilityFeatureCategory.toLowerCase() ===
							VulnerabilityFeatureCategory.Severity.toLowerCase() &&
						selectedFeatureList.find(
							(item: string) => item === vulnerability.severity,
						)
					) {
						filteredVulnerabilities.push(vulnerability)
						filteredSandboxes.push(
							...extractSandboxesFromVulnerabilityBasedOnSelectedFeature(
								vulnerability,
								selectedFeatureList,
								vulnerabilityFeatureCategory,
							),
						)
					}
				})
				return {
					vulnerabilities: filteredVulnerabilities,
					sandboxes: filteredSandboxes,
				}
			}

			const filterVulnerabilitiesBasedOnFeature = (
				filteredVulnerabilities: {
					vulnerabilities: Vulnerability[]
					sandboxes: SandBox[]
				},
				vulnerabilityFeatureCategory: string,
			) => {
				const selectedFeatureList = new URLSearchParams(
					window.location.search,
				).getAll(vulnerabilityFeatureCategory.toLowerCase())
				if (selectedFeatureList.length > 0) {
					if (filteredVulnerabilities.sandboxes.length > 0) {
						if (
							(vulnerabilityFeatureCategory &&
								vulnerabilityFeatureCategory.toLowerCase() ===
									VulnerabilityFeatureCategory.Language.toLowerCase()) ||
							vulnerabilityFeatureCategory.toLowerCase() ===
								VulnerabilityFeatureCategory.Technology.toLowerCase()
						) {
							filteredVulnerabilities.sandboxes =
								filterSandboxesBasedOnSelectedFeatureList(
									filteredVulnerabilities.sandboxes,
									selectedFeatureList,
									vulnerabilityFeatureCategory,
								)
						} else if (
							vulnerabilityFeatureCategory &&
							vulnerabilityFeatureCategory.toLowerCase() ===
								VulnerabilityFeatureCategory.OWASP.toLowerCase()
						) {
							const filteredSandboxes: SandBox[] = []
							filteredVulnerabilities.vulnerabilities.forEach(
								(vulnerability: Vulnerability) => {
									for (let i = 0; i < vulnerability.owasps.length; i += 1) {
										if (
											selectedFeatureList.find(
												(item: string) =>
													item.toLowerCase() === vulnerability.owasps[i].slug.toLowerCase(),
											)
										) {
											// eslint-disable-next-line no-loop-func
											vulnerability.sandboxes.forEach((sandbox: SandBox) => {
												const filteredSandbox = filteredVulnerabilities.sandboxes.find(
													(item: SandBox) =>
														item.id === sandbox.id &&
														item.vulnerabilitySlug === vulnerability.slug,
												)
												if (filteredSandbox) {
													filteredSandboxes.push(filteredSandbox)
												}
											})
											break
										}
									}
								},
							)
							filteredVulnerabilities.sandboxes = filteredSandboxes
						} else if (
							vulnerabilityFeatureCategory &&
							vulnerabilityFeatureCategory.toLowerCase() ===
								VulnerabilityFeatureCategory.CWE.toLowerCase()
						) {
							const filteredSandboxes: SandBox[] = []
							filteredVulnerabilities.vulnerabilities.forEach(
								(vulnerability: Vulnerability) => {
									for (let i = 0; i < vulnerability.cwes.length; i += 1) {
										if (
											selectedFeatureList.find(
												(item: string) =>
													item.toLowerCase() === vulnerability.cwes[i].slug.toLowerCase(),
											)
										) {
											// eslint-disable-next-line no-loop-func
											vulnerability.sandboxes.forEach((sandbox: SandBox) => {
												const filteredSandbox = filteredVulnerabilities.sandboxes.find(
													(item: SandBox) =>
														item.id === sandbox.id &&
														item.vulnerabilitySlug === vulnerability.slug,
												)
												if (filteredSandbox) {
													filteredSandboxes.push(filteredSandbox)
												}
											})
											break
										}
									}
								},
							)
							filteredVulnerabilities.sandboxes = filteredSandboxes
						} else if (
							vulnerabilityFeatureCategory &&
							vulnerabilityFeatureCategory.toLowerCase() ===
								VulnerabilityFeatureCategory.Severity.toLowerCase()
						) {
							const filteredSandboxes: SandBox[] = []
							filteredVulnerabilities.vulnerabilities.forEach(
								(vulnerability: Vulnerability) => {
									if (
										selectedFeatureList.find(
											(feature: string) =>
												feature.toLowerCase() === vulnerability.severity.toLowerCase(),
										)
									) {
										vulnerability.sandboxes.forEach((sandbox: SandBox) => {
											const filteredSandbox = filteredVulnerabilities.sandboxes.find(
												(item: SandBox) =>
													item.id === sandbox.id &&
													item.vulnerabilitySlug === vulnerability.slug,
											)
											if (filteredSandbox) {
												filteredSandboxes.push(filteredSandbox)
											}
										})
									}
								},
							)
							filteredVulnerabilities.sandboxes = filteredSandboxes
						}
					} else {
						// if, there is no item in the filteredVulnerabilities list (come through parameter), that means, there was no previous filtered vulnerabilities based on other feature, and that's why we are passing action.payload.vulnerabilities
						filteredVulnerabilities = filterVulnerabilitiesBasedOnSelectedFeatureList(
							action.payload.vulnerabilities,
							selectedFeatureList,
							vulnerabilityFeatureCategory,
						)
					}
				}
				return filteredVulnerabilities
			}

			// the following filteredVulnerabilities contain all filtered vulnerabilities and sandboxes based on selected features
			let filteredVulnerabilities: {
				vulnerabilities: Vulnerability[]
				sandboxes: SandBox[]
			} = { vulnerabilities: [], sandboxes: [] }
			// get all selected languages from the url language query param
			const selectedLanguageFeatureList = new URLSearchParams(
				window.location.search,
			).getAll(VulnerabilityFeatureCategory.Language.toLowerCase())
			if (selectedLanguageFeatureList.length > 0) {
				// first filter vulnerabilities based on selected languages
				filteredVulnerabilities = filterVulnerabilitiesBasedOnSelectedFeatureList(
					action.payload.vulnerabilities,
					selectedLanguageFeatureList,
					VulnerabilityFeatureCategory.Language,
				)
			}

			// get all selected technologies from the url technology query param
			filteredVulnerabilities = filterVulnerabilitiesBasedOnFeature(
				filteredVulnerabilities,
				VulnerabilityFeatureCategory.Technology,
			)

			// get all selected owasp from the url owasp query param
			filteredVulnerabilities = filterVulnerabilitiesBasedOnFeature(
				filteredVulnerabilities,
				VulnerabilityFeatureCategory.OWASP,
			)

			// get all selected cwe from the url cwe query param
			filteredVulnerabilities = filterVulnerabilitiesBasedOnFeature(
				filteredVulnerabilities,
				VulnerabilityFeatureCategory.CWE,
			)

			// get all selected cwe from the url cwe query param
			filteredVulnerabilities = filterVulnerabilitiesBasedOnFeature(
				filteredVulnerabilities,
				VulnerabilityFeatureCategory.Severity,
			)

			if (filteredVulnerabilities.vulnerabilities.length === 0) {
				// means there is no result, do the following
				// now, check all feature list in url params, whether any of the feature is currently selected or not; if there is no selected feature in the url params and also the search field does not contain any search value
				// then simply return all vulnerabilities to display
				if (!checkUrlParamsIfAnyFeatureExists()) {
					state.filteredVulnerabilities = action.payload.vulnerabilities
					// since, there is not feature selection, so by default get all sandboxes or challenges from all vulnerabilities
					state.filteredVulnerabilities?.forEach((vulnerability: Vulnerability) => {
						vulnerability.sandboxes.forEach((sandbox: SandBox) => {
							const filteredSandbox = filteredVulnerabilities.sandboxes.find(
								(item: SandBox) => item.id === sandbox.id,
							)
							// the following check, if sandbox does not exist or sandbox exists but vulnerability slug is different, then add that to the list
							if (
								!filteredSandbox ||
								(filteredSandbox &&
									filteredSandbox.vulnerabilitySlug !== vulnerability.slug)
							) {
								filteredVulnerabilities.sandboxes.push({
									...sandbox,
									vulnerabilityTitle: vulnerability.title,
									createdAt: vulnerability.created_at,
									vulnerabilitySlug: vulnerability.slug,
									description: vulnerability.description,
									severity: vulnerability.severity,
								})
							}
						})
					})
					state.filteredSandboxes = filteredVulnerabilities.sandboxes
					return state
				}
			}
			state.filteredVulnerabilities = filteredVulnerabilities.vulnerabilities
			filteredVulnerabilities.sandboxes.sort(
				(sandbox1: SandBox, sandbox2: SandBox) => {
					const sandboxCreatedAtDifference =
						new Date(sandbox1.createdAt).getMilliseconds() -
						new Date(sandbox2.createdAt).getMilliseconds()
					if (sandboxCreatedAtDifference !== 0) return sandboxCreatedAtDifference
					// sort based on title, if create at date are same
					return sandbox1.vulnerabilityTitle.localeCompare(
						sandbox2.vulnerabilityTitle,
					)
				},
			)
			state.filteredSandboxes = filteredVulnerabilities.sandboxes
			return state
		},
		selectVulnerability: (state, action: PayloadAction<SandBox>) => {
			state.selectedVulnerabilities.push(action.payload)
			return state
		},
		deselectVulnerability: (state, action: PayloadAction<SandBox>) => {
			state.selectedVulnerabilities = state.selectedVulnerabilities.filter(
				vulnerability =>
					!(
						vulnerability.vulnerabilityTitle === action.payload.vulnerabilityTitle &&
						vulnerability.challengeTitle === action.payload.challengeTitle
					),
			)
			return state
		},
		setSelectedVulnerabilities: (state, action: PayloadAction<SandBox[]>) => {
			state.selectedVulnerabilities = action.payload
			return state
		},
		clearSelectedVulnerabilities: state => {
			state.selectedVulnerabilities = []
			return state
		},
		setPageNumber:  (state, action: PayloadAction<number>) => {
			state.pageNumber = action.payload 
			return state
		},
	},
})
export const {
	addVulnerabilities,
	searchVulnerabilities,
	selectVulnerability,
	deselectVulnerability,
	clearSelectedVulnerabilities,
	setSelectedVulnerabilities,
	setPageNumber,
} = vulnerabilitiesSlice.actions
export default vulnerabilitiesSlice.reducer
