import { CircularProgress, Typography } from '@mui/material'
import { useState } from 'react'
import { LoadingContext } from '../../../api/hooks'
import SecDimLogo from '../../../assets/logo.svg'
import * as Styled from './FullPageLoaderStyles'

function FullPageLoader({
	className,
	overlay = "false",
	children,
	message = '',
}: FullPageLoaderProps) {
	const [loading, setLoading] = useState(false)

	function toggleLoading(val: boolean) {
		setLoading(val)
	}

	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<LoadingContext.Provider value={{ loading, toggleLoading }}>
			<div className={className}>
				<Styled.HideWrapper hide={(!loading).toString()}>
					<Styled.LoaderInnerWrapper overlay={overlay.toString()}>
						<Styled.LogoWrapper>
							<img src={SecDimLogo} alt='secdim Logo' />
						</Styled.LogoWrapper>
						<CircularProgress />
						<Typography>{message}</Typography>
					</Styled.LoaderInnerWrapper>
				</Styled.HideWrapper>
				<Styled.HideWrapper hide={loading.toString()}>{children}</Styled.HideWrapper>
			</div>
		</LoadingContext.Provider>
	)
}

export default FullPageLoader
