import { Close } from '@mui/icons-material'
import { Box, Typography, Dialog, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import SecDimButton from '../../../shared/components/SecDimButton/SecDimButton'

export const SecdimBlue = styled('span')(({ theme }) => ({
	color: `${theme.palette.primary.main}!important`,
	fontWeight: 'bold',
}))

export const DialogImage = styled('img')({
	width: '100%',
	borderRadius: '5px',
	maxHeight: '300px'
	
})

export const AbsoluteClose = styled(Close)({
	position: 'absolute',
	top: 20,
	right: 20,
	borderRadius: 10,
	cursor: 'pointer',
})

export const DialogTitleText = styled(Typography)(({ theme }) => ({
	marginTop: '10px',
	textAlign: 'center',

	[theme.breakpoints.down('md')]: {
		marginTop: '30px'
	}
}))

export const CustomSecDimButton = styled(SecDimButton)({
	textTransform: 'none',
	fontWeight: 600,
	padding: '10px 16px',
	marginTop: '10px',
	display: 'inline-block'
})

export const ButtonWrapper = styled(Box)({
	textAlign: 'center',
})

export const DialogSpace = styled(Dialog)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'flex-start',
	'& .MuiPaper-root': {
		maxWidth: 'none !important', 
		width: '80%'
	},
})

export const DialogContentWrapper = styled(Box, {
	shouldForwardProp: (prop) => prop !== "hasDivider"
})<{ hasDivider?: boolean }>(({ hasDivider, theme }) => ({
	borderColor: '#55C1E2',
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch", 

	borderRight: hasDivider ? "1px solid #55C1E2" : "none",
	[theme.breakpoints.down('md')]: {
		borderRight: "none",
		borderBottom: hasDivider ? "1px solid #55C1E2" : "none",
	},
}))

export const DialogContentGridItem = styled(Grid)({
	
})

export const DialogContent = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 30,
});

export const ImageContainer = styled(Box)(({ theme }) => ({
	flex: "0 0 auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",

	[theme.breakpoints.down('mdm')]: {
		height: "100%",
    	maxHeight: "100%", 
	},
}))

export const DialogContentGrid = styled(Grid)(({ theme }) => ({
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
	flexDirection: "row",
	marginTop: '20px',

	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
		marginTop: '20px'
	},
}))