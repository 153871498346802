// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body>iframe {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    position: relative;
}

#secdimrouter>div {
    height: 100%;
}

#root {
    height: 100%;
}

.cm-editor,
.cm-gutters {
    background-color: transparent;
    padding-left: 20px;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/style.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT;;kBAEc;IACd,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;iBACa;AACjB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;;IAEI,sBAAsB;AAC1B;;AAEA;;IAEI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;;IAEI,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":["body {\n    margin: 0;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n        sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\nbody>iframe {\n    display: none;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n        monospace;\n}\n\n* {\n    box-sizing: border-box;\n}\n\n*:before,\n*:after {\n    box-sizing: border-box;\n}\n\nhtml,\nbody {\n    height: 100%;\n    position: relative;\n}\n\n#secdimrouter>div {\n    height: 100%;\n}\n\n#root {\n    height: 100%;\n}\n\n.cm-editor,\n.cm-gutters {\n    background-color: transparent;\n    padding-left: 20px;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
