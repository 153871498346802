import { Typography, Grid, useMediaQuery } from '@mui/material'
import logo from '../../assets/logo.84184ff1.svg'
import * as Styled from './FooterStyles'
import theme from '../../utils/theme'

function Footer() {
	return (
	<Styled.FooterContainer>
		<Styled.FooterInnerContainer>
			<Styled.FooterCopyrightContainer>
				<Grid container direction="column">
					<Grid item>
						<Typography variant='subtitle1' align={useMediaQuery(theme.breakpoints.down('md')) ? 'center' : 'left'}>
							Copyright © {new Date().getFullYear()} Security Dimension Pty Ltd.
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='subtitle1' align={useMediaQuery(theme.breakpoints.down('md')) ? 'center' : 'left'} sx={{ fontSize: '7px' }}>
							Any third-party logos, brands, and/or images are the property of their respective copyright or trademark holders and are provided for illustrative purposes only.
						</Typography>
					</Grid>
				</Grid>
			</Styled.FooterCopyrightContainer>
			<Styled.FooterLogoContainer>
				<Styled.FooterLogo href='https://secdim.com'>
					<img src={logo} alt='secdim logo' />
				</Styled.FooterLogo>
			</Styled.FooterLogoContainer>
			<Styled.FooterNav>
				<Styled.FooterNavItem href='https://secdim.com/terms-of-service'>
					Terms of Service
				</Styled.FooterNavItem>
				<Styled.FooterNavItem href='https://secdim.com/privacy-policy'>
					Privacy Policy
				</Styled.FooterNavItem>
				<Styled.FooterNavItem href='https://secdim.com/about/'>
					About
				</Styled.FooterNavItem>
			</Styled.FooterNav>
		</Styled.FooterInnerContainer>
	</Styled.FooterContainer>
	);
}
export default Footer
