import { useEffect, useState } from 'react'
import TrialOffer from '../../../pages/Challenge/components/VictoryOrDefeat/components/TrialOffer/TrialOffer'

function DisplayTrial() {
	const trial = new URLSearchParams(window.location.search).get('trial')
	const [isTrialDialogOpen, setIsTrialDialogOpen] = useState(false)

	useEffect(() => {
		if (trial === 'true') {
			setIsTrialDialogOpen(true)
		} else {
			setIsTrialDialogOpen(false)
		}
	}, [trial])

	const onCloseButton = () => {
		setIsTrialDialogOpen(!isTrialDialogOpen)
	}

	return (
		<TrialOffer
				title='Try Pro Subscription For Free!'
				features='Access to Cloud Development Environment,Unlock Professional Games & Challenges,Collect Awards and Badges,7 days free trial,...more'
				buttonText='Start Your 7-day Trial'
				isOpenDialog={isTrialDialogOpen}
				onCloseButton={onCloseButton}
			/>
	)
}

export default DisplayTrial
