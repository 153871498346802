import { Typography, Link, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import Alert from '@mui/material/Alert';
// import theme from '../../../utils/theme';

export const AlertBody = styled(Alert)({
	color: 'rgb(123 90 32)',
	position: 'relative',
	zIndex: 999,

	// [theme.breakpoints.up('sm')]: {
	// 	left: '10%'
	// },
	// [theme.breakpoints.up('md')]: {
	// 	left: '25%'
	// },
	// [theme.breakpoints.up('lg')]: {
	// 	left: '50%'
	// },
	// [theme.breakpoints.up('xl')]: {
	// 	left: '75%'
	// },
	// [theme.breakpoints.up('hr')]: {
	// 	left: '100%'
	// },
	// [theme.breakpoints.up('k4')]: {
	// 	left: '225%'
	// },

})

export const AlertMessageWrapper = styled(Box)(({ theme }) => ({
	position: 'fixed',
	marginTop: 12,
	zIndex: 999,
	width: '76.2%',
	[theme.breakpoints.down('lg')]: {
		width: '94.7%'
	},
	[theme.breakpoints.down('smm')]: {
		width: '91.2%'
	},
}))


export const AlertMessage = styled(Typography)({
	marginTop: '-2px',
})

export const AlertLink = styled(Link)({
	cursor: 'pointer !important'
})
