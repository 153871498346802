import { Save } from '@mui/icons-material'
import { Alert, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import playBackendRequestHandler from '../../../../api/playBackendRequestHandler'
import * as Styled from './CreateDepartmentDialogStyles'

function CreateDepartmentDialog({ isOpenDialog, handleCloseDialog, refetchCompanyData }: CreateDepartmentDialogProps) {
    const [departmentName, setDepartmentName] = useState<string>('')
    const [isInvalidDepartmentName, setIsInvalidDepartmentName] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [createDepartmentResponseMessage, setCreateDepartmentResponseMessage] = useState({ error: '', message: '' })

    const createDepartment = (submitDepartmentName: string) => {
        if (departmentName === "") {
            setIsInvalidDepartmentName(true)
        }
        if (departmentName !== "" && !isInvalidDepartmentName) {
            setIsLoading(true)

            const data = {
                "department_name": submitDepartmentName
            }

            playBackendRequestHandler(
                'createDepartment',
                data,
                undefined,
                undefined
            ).then((resp: any) => {
                setIsLoading(false)
                setDepartmentName("")
                setCreateDepartmentResponseMessage({ error: '', message: resp.data })
                refetchCompanyData.refetch()
            }).catch((err: any) => {
                setCreateDepartmentResponseMessage({ error: err.response.data.detail, message: '' })
                setIsLoading(false)
                setDepartmentName("")
            })
        }
    }

    const closeDialog = () => {
        handleCloseDialog()
        setDepartmentName("")
        setIsInvalidDepartmentName(false)
        setIsLoading(false)
        setCreateDepartmentResponseMessage({ error: '', message: '' })
    }

    return (
        <Styled.DialogSpace id="DialogSpace" onClose={() => closeDialog} open={isOpenDialog}>
            <Styled.AbsoluteClose onClick={closeDialog} />
            <Styled.DialogContentGrid container>
                <Styled.DialogContent>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Styled.DialogTitleText variant='h3'>
                                Create a Department
                            </Styled.DialogTitleText>
                        </Grid>
                        <Grid item xs={12} pb={5}>
                            <Styled.DialogTitleText>
                                Create a department to assign members to.
                            </Styled.DialogTitleText>
                        </Grid>
                        <Grid item xs={12} pb={5}>
                            <Styled.CssTextField
                                error={isInvalidDepartmentName}
                                variant='outlined'
                                color='primary'
                                fullWidth
                                label='Department Name'
                                type='text'
                                placeholder='Enter department name here'
                                value={departmentName}
                                onChange={(e: any) => {
                                    setDepartmentName(e.target.value)
                                }}
                                onClick={() => {
                                    setIsInvalidDepartmentName(false)
                                }}
                            />
                            {isInvalidDepartmentName ? <Styled.InfoText isError variant="h6" align="left">
                                Department name must not be empty
                            </Styled.InfoText> : null}
                        </Grid>
                        <Grid item xs={1} pb={2.5}>
                            {isLoading ?
                                <Styled.CustomLoadingButton loading loadingPosition="start" startIcon={<Save />}>
                                    <Typography>Please Wait</Typography>
                                </Styled.CustomLoadingButton>
                                :
                                <Styled.CreateDepartmentButton onClick={() => createDepartment(departmentName)}>
                                    Create Department
                                </Styled.CreateDepartmentButton>
                            }
                        </Grid>
                        <Grid item xs={1} >
                            {createDepartmentResponseMessage.error !== "" ?
                                // eslint-disable-next-line react/jsx-no-undef
                                <Alert severity="error">
                                    {createDepartmentResponseMessage.error}
                                </Alert>
                                :
                                null
                            }
                            {createDepartmentResponseMessage.message !== "" ?
                                // eslint-disable-next-line react/jsx-no-undef
                                <Alert severity="success" sx={{ width: "100%" }}>
                                    {createDepartmentResponseMessage.message}
                                </Alert>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </Styled.DialogContent>
            </Styled.DialogContentGrid>
        </Styled.DialogSpace >
    )
}
export default CreateDepartmentDialog
