import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusStateInText } from '../enums';

const initialState: { commits: Commit[], gameSlug: string, challengeSlug: string }[] | null = []
const commitListBundleSlice = createSlice({
    name: 'CommitList',
    initialState,
    reducers: {
        setCommitListBundle: (state: { commits: Commit[], gameSlug: string, challengeSlug: string }[], action: PayloadAction<{ commits: Commit[], gameSlug: string, challengeSlug: string }>) => {
            const findCommitList = state.find((commitList) =>
                commitList.gameSlug === action.payload.gameSlug &&
                commitList.challengeSlug === action.payload.challengeSlug)
            if (findCommitList) {
                findCommitList.commits = action.payload.commits // overwrite existing item
            }
            else {
                state.push(action.payload) // pushed new one
            }
            return state
        },
        setCommit: (state: { commits: Commit[], gameSlug: string, challengeSlug: string }[], action: PayloadAction<{ commit: Commit, gameSlug: string, challengeSlug: string }>) => {
            // search commit list based on game slug and challenge slug
            const findCommitList = state.find((commitList) =>
                commitList.gameSlug === action.payload.gameSlug &&
                commitList.challengeSlug === action.payload.challengeSlug)

            if (findCommitList) {
                if (findCommitList.commits.length >= 5) {
                    findCommitList.commits.pop()
                }

                if (findCommitList.commits.length > 0) {
                    // the below if condition is because if a commit is not tested yet or already waiting and a new commit already arrived, then
                    // then make the old commit untested
                    if (findCommitList.commits[0].status.status_state === StatusStateInText.Waiting ||
                        findCommitList.commits[0].status.status_state === StatusStateInText.ReadyForTest
                    ) {
                        findCommitList.commits[0].status.status_state = StatusStateInText.Untested
                        const searchForRunningCommits = findCommitList.commits.find(commit => commit.status.status_state === StatusStateInText.Running)
                        if (searchForRunningCommits) {
                            findCommitList.commits.unshift({
                                ...action.payload.commit,
                                status: {
                                    ...action.payload.commit.status,
                                    status_state: StatusStateInText.Waiting
                                }
                            })
                        }
                        else {
                            findCommitList.commits.unshift(action.payload.commit)
                        }
                    }
                    // make the coming new commit waiting, but the current commit is already running
                    else if (findCommitList.commits[0].status.status_state === StatusStateInText.Running) {
                        findCommitList.commits.unshift({
                            ...action.payload.commit,
                            status: {
                                ...action.payload.commit.status,
                                status_state: StatusStateInText.Waiting
                            }
                        })
                    }
                    else {
                        findCommitList.commits.unshift(action.payload.commit)
                    }
                }
                else {
                    findCommitList.commits.unshift(action.payload.commit)
                }
            }
            else {
                state.push({ commits: [action.payload.commit], gameSlug: action.payload.gameSlug, challengeSlug: action.payload.challengeSlug })
            }
            return state
        },
        removeCommitListFromBundle: (state: { commits: Commit[], gameSlug: string, challengeSlug: string }[], action: PayloadAction<{ gameSlug: string, challengeSlug: string }>) => {
            // eslint-disable-next-line no-param-reassign
            state = state.filter((commitList) => commitList.gameSlug !== action.payload.gameSlug && commitList.challengeSlug !== action.payload.challengeSlug)
            return state
        }
    },
});
export const { setCommitListBundle, setCommit, removeCommitListFromBundle } = commitListBundleSlice.actions;
export default commitListBundleSlice.reducer;

