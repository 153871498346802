import { configureStore } from '@reduxjs/toolkit';

import commitListBundleReducer from './commitListBundleSlice';
import publishedAppListBundleReducer from './publishedAppListBundleSlice';
import challengeStateReducer from './challengeStateSlice'
import forkURIReducer from './forkURISlice'
import challengeDialogPromptReducer from './challengeDialogPromptSlice'
import cdeOpenDialogPromptReducer from './cdeOpenDialogPromptSlice'
import challengeHintListReducer from './challengeHintListSlice'
import challengeHintDescriptionReducer from './challengeHintDescriptionSlice'
import latestChallengeAttemptReducer from './latestChallengeAttemptSlice'
import commitBuildLogsSliceReducer from './commitBuildLogsSlice';
import battleActivityTableSliceReducer from './battleActivityTableSlice'
import workspaceStatusReducer from './workspaceStatusSlice'
import vulnerabilitiesReducer from './vulnerabilitiesSlice'
import vulnerabilityFeatureReducer from './vulnerabilityFeatureSlice'


export const store = configureStore({
    reducer:
    {
        commitListBundle: commitListBundleReducer,
        challengeState: challengeStateReducer,
        forkURI: forkURIReducer,
        challengeDialogPrompt: challengeDialogPromptReducer,
        latestChallengeAttempt: latestChallengeAttemptReducer,
        commitBuildLogs: commitBuildLogsSliceReducer,
        challengeHintList: challengeHintListReducer,
        challengeHintDescription: challengeHintDescriptionReducer,
        publishedAppListBundle: publishedAppListBundleReducer,
        battleActivityTable: battleActivityTableSliceReducer,
        workspaceStatus: workspaceStatusReducer,
        vulnerabilities: vulnerabilitiesReducer,
        vulnerabilityFeature: vulnerabilityFeatureReducer,
        cdeOpenDialogPrompt: cdeOpenDialogPromptReducer
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
