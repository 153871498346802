import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { VulnerabilityFeatureCategory } from '../enums';

const initialState: VulnerabilitiesSearchFeatureInfo[] = []
const vulnerabilityFeatureSlice = createSlice({
    name: 'VulnerabilityFeature',
    initialState,
    reducers: {
        extractVulnerabilityFeatureList: (state, action: PayloadAction<Vulnerability[]>) => {
            // the following loop will extract unique language and technology category tags in the two separate lists including unique cwe, owasp, impact, severity, likelihood lists.
            // unique term is used because we are iterating vulnerability list, so, same tag, cwe might be available in different vulnerabilities, but we need to extract once only
            const flag: any = {} // to keep track unique tag
            const flagForSeverity: any = {} // to keep track unique severity value
            const uniqueLanguageTagList: VulnerabilityFeature[] = []
            const uniqueTechnologyTagList: VulnerabilityFeature[] = []
            const uniqueCWEList: VulnerabilityFeature[] = []
            const uniqueOWASPList: VulnerabilityFeature[] = []
            const uniqueSeverityList: VulnerabilityFeature[] = []
            action.payload.forEach((vulnerability: Vulnerability) => {
                // separate language and technology tag list
                vulnerability.sandboxes.forEach((sandbox: SandBox) => {
                    sandbox.tags.forEach((tag: Tag) => {
                        if (!flag[tag.slug]) {
                            flag[tag.slug] = true
                            if (tag.category && tag.category.toLowerCase() === VulnerabilityFeatureCategory.Language.toLowerCase())
                                uniqueLanguageTagList.push({
                                    ...tag,
                                })
                            else if (tag.category && tag.category.toLowerCase() === VulnerabilityFeatureCategory.Technology.toLowerCase())
                                uniqueTechnologyTagList.push({
                                    ...tag,
                                })
                        }
                    })
                })
                // separate cwe list
                vulnerability.cwes.forEach((cwe: CWE) => {
                    if (!flag[cwe.slug]) {
                        flag[cwe.slug] = true
                        uniqueCWEList.push({
                            name: `${cwe.cwe_id} - ${cwe.title}`,
                            slug: cwe.slug,
                        })
                    }
                })
                // separate owasp list
                vulnerability.owasps.forEach((owasp: OWASP) => {
                    if (!flag[owasp.slug]) {
                        flag[owasp.slug] = true
                        uniqueOWASPList.push({
                            name: `${owasp.owasp_id} - ${owasp.title}`,
                            slug: owasp.slug,
                        })
                    }
                })
                // separate severity list
                // Note: we are assigning same value for both name and slug just to use reusable component because that components requires both values name and slug, but we have only one value for severity 
                if (!flagForSeverity[vulnerability.severity]) {
                    flagForSeverity[vulnerability.severity] = true
                    uniqueSeverityList.push({
                        name: vulnerability.severity,
                        slug: vulnerability.severity,
                    })
                }
            })

            // sort all the list that were extracted in the previous loop
            uniqueLanguageTagList.sort((vulnerabilityFeature1: VulnerabilityFeature, vulnerabilityFeature2: VulnerabilityFeature) => vulnerabilityFeature1.slug.localeCompare(vulnerabilityFeature2.slug))
            uniqueTechnologyTagList.sort((vulnerabilityFeature1: VulnerabilityFeature, vulnerabilityFeature2: VulnerabilityFeature) => vulnerabilityFeature1.slug.localeCompare(vulnerabilityFeature2.slug))
            uniqueCWEList.sort((vulnerabilityFeature1: VulnerabilityFeature, vulnerabilityFeature2: VulnerabilityFeature) => {
                const cweIdOne = parseInt(vulnerabilityFeature1.name.split(' - ')[0], 10);
                const cweIdTwo = parseInt(vulnerabilityFeature2.name.split(' - ')[0], 10);
                return cweIdOne - cweIdTwo
            })
            uniqueOWASPList.sort((vulnerabilityFeature1: VulnerabilityFeature, vulnerabilityFeature2: VulnerabilityFeature) => vulnerabilityFeature1.name.localeCompare(vulnerabilityFeature2.name))
            uniqueSeverityList.sort((vulnerabilityFeature1: VulnerabilityFeature, vulnerabilityFeature2: VulnerabilityFeature) => vulnerabilityFeature1.slug.localeCompare(vulnerabilityFeature2.slug))

            // add list to redux state
            // eslint-disable-next-line no-param-reassign
            state = []
            state.push({
                vulnerabilityFeatureCategory: VulnerabilityFeatureCategory.Language,
                vulnerabilityFeatureList: uniqueLanguageTagList
            })
            state.push({
                vulnerabilityFeatureCategory: VulnerabilityFeatureCategory.Technology,
                vulnerabilityFeatureList: uniqueTechnologyTagList
            })
            state.push({
                vulnerabilityFeatureCategory: VulnerabilityFeatureCategory.OWASP,
                vulnerabilityFeatureList: uniqueOWASPList
            })
            state.push({
                vulnerabilityFeatureCategory: VulnerabilityFeatureCategory.CWE,
                vulnerabilityFeatureList: uniqueCWEList
            })
            state.push({
                vulnerabilityFeatureCategory: VulnerabilityFeatureCategory.Severity,
                vulnerabilityFeatureList: uniqueSeverityList
            })
            return state
        },
    },
});
export const { extractVulnerabilityFeatureList } = vulnerabilityFeatureSlice.actions;
export default vulnerabilityFeatureSlice.reducer;