import { CircularProgress } from '@mui/material'
import SecDimLogo from '../../../assets/logo.svg'
import * as Styled from './LoaderStyles'

function Loader({ className, overlay = 'false', message = '' }: LoadingProps) {
	// TODO: Rework this, at some point this has become absolute? it shouldnt be absolute
	return (
		<div className={className}>
			<Styled.LoaderOuterWrapper overlay={overlay.toString()}>
				<Styled.LoaderInnerWrapper overlay={overlay.toString()}>
					<Styled.LogoWrapper>
						<img src={SecDimLogo} alt='secdim Logo' />
					</Styled.LogoWrapper>
					<CircularProgress />
				</Styled.LoaderInnerWrapper>
				<Styled.Message>{message}</Styled.Message>
			</Styled.LoaderOuterWrapper>
		</div>
	)
}

export default Loader
