import { Done } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import SecDimButton from '../../../../../../shared/components/SecDimButton/SecDimButton'
import * as Styled from './TrialOfferStyles'

function TrialOffer({
	title,
	features,
	buttonText,
	isOpenDialog,
	onCloseButton,
}: TrialOfferProps) {
	const checkCircle = (
		<Box mr={1}>
			<Done color='primary' height='5px' width='5px' />
		</Box>
	)

	const redirectPlayerToSubscription = () => {
		sessionStorage.setItem(
			'redirectURI',
			`${window.location.toString().replace(window.location.search, '')}`
		)
		window.location.href = `${process.env.REACT_APP_SECDIM_ID_FRONTEND}subscription/trial?origin=play`
	}

	return (
		<Styled.TrialDialog onClose={onCloseButton} open={isOpenDialog} maxWidth='sm'>
			<Styled.TrialWrapper px={2} py={5} minHeight='inherit'>
				<Styled.AbsoluteClose onClick={onCloseButton} />
				<Box display='flex' justifyContent='center' pb={4}>
					<Typography align='center' variant='h5'>
						{title}
					</Typography>
				</Box>
				<Box
					display='flex'
					justifyContent='center'
					pl={5}
					pr={5}
					flexDirection='column'
				>
					{features.split(',').map((feature: string) => (
						<Grid item key={feature}>
							<Box display='flex' flexDirection='row'>
								{checkCircle}
								<Typography>{feature.trim()}</Typography>
							</Box>
						</Grid>
					))}
				</Box>
				<Styled.ButtonWrapper>
					<SecDimButton onClick={redirectPlayerToSubscription}>
						{buttonText}
					</SecDimButton>
				</Styled.ButtonWrapper>
			</Styled.TrialWrapper>
		</Styled.TrialDialog>
	)
}

export default TrialOffer
