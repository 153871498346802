import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ErrorPageIcon = styled(Box)(({ theme }) => ({
	height: '150px',
	width: '150px',
	margin: '0 auto',

	'& > svg': {
		width: '100%',
		height: '100%',
		color: theme.palette.primary.main,
	},

	[theme.breakpoints.down('sm')]: {
		width: 100,
		height: 100,
	},
}))

export const ErrorBox = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		minHeight: '50vh',
		marginTop: '15%',
	},
}))

export const ErrorTitle = styled(Typography)(({ theme }) => ({
	textAlign: 'center',

	[theme.breakpoints.down('sm')]: {
		fontSize: '1.875rem',
		fontWeight: 600,
	},
}))

export const ErrorSubtitle = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	color: theme.palette.text.secondary,

	[theme.breakpoints.down('sm')]: {
		fontSize: '1rem',
	},
}))

export const ButtonWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	marginTop: '2rem',
	flexDirection: 'row',
})
