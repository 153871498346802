import { Grid, Skeleton } from '@mui/material'
import { Department } from '../../../../enums'
import Tooltip from '../../../../shared/components/Tooltip/Tooltip'
import { getGradeAndEfficiencyLevel } from '../../../../shared/helpers/utils'
import * as Styled from './CompanyDashboardInfoStyles'

const mediaCard = (content: any) => <Styled.MediaCard>
    <Styled.MediaCardContent>
        {content}
    </Styled.MediaCardContent>
</Styled.MediaCard>

const companyComparativeGradeFrontAndBackCard = (cardSideClassName: string, companyGradeTitle: string, companyComparativeGradeData: any, overallGradeAndEfficiencyLevel: any) => <Styled.CompanyComparativeGradeCard className={cardSideClassName}>
    <Styled.MediaCardNameContainer>
        <Styled.MediaCardName>
            {companyGradeTitle}
            <Tooltip title="Company Comparative Grade: Your team's engagement and skill level in compare with other teams. Grade is calculated based on Z score and percentile rank. It shows how many standard deviations your team's score is away from the mean score of all other teams."
                placement='top-start'>
                <Styled.HelpIcon />
            </Tooltip>
        </Styled.MediaCardName>
    </Styled.MediaCardNameContainer>
    <Tooltip title={`Total score: ${companyComparativeGradeData.totalScore}, Z-score: ${companyComparativeGradeData.zIndex}`}
        placement='top-start'>
        <Styled.MediaCardBody>{overallGradeAndEfficiencyLevel.grade || "-"}</Styled.MediaCardBody>
    </Tooltip>
    <Styled.CompanyEfficiencyLevel>{overallGradeAndEfficiencyLevel.efficiencyLevel}</Styled.CompanyEfficiencyLevel>
</Styled.CompanyComparativeGradeCard>


function CompanyDashboardInfo({ companyPlayersActivitiesData, companyComparativeGradeData, department }: CompanyDashboardInfoProps) {
    let overallGradeAndEfficiencyLevel = null;
    let departmentData = null;
    if (department === Department.Default) {
        overallGradeAndEfficiencyLevel = getGradeAndEfficiencyLevel(companyComparativeGradeData.overall_company_comparative_z_score, companyComparativeGradeData.overall_company_comparative_percentile_rank)
    } else {
        departmentData = companyComparativeGradeData.department_grades?.find((data: any) => data.department_name === department)
        if (departmentData) {
            overallGradeAndEfficiencyLevel = getGradeAndEfficiencyLevel(departmentData.overall_department_comparative_z_score, departmentData.overall_department_comparative_percentile_rank)
        } else {
            overallGradeAndEfficiencyLevel = getGradeAndEfficiencyLevel(0, 0)
        }
    }
    let totalNumberOfAttempts = 0;
    if (companyPlayersActivitiesData && companyPlayersActivitiesData.player_challenge_attempts) {
        if (department === Department.Default) {
            totalNumberOfAttempts = companyPlayersActivitiesData.player_challenge_attempts.reduce((partialSum, attempt) => partialSum + attempt.total_challenge_attempts, 0)
        } else {
            totalNumberOfAttempts = companyPlayersActivitiesData.player_challenge_attempts
                .filter(attempt => attempt.player_department === department)
                .reduce((partialSum, attempt) => partialSum + attempt.total_challenge_attempts, 0);
        }
    }

    return <Grid container spacing={2}>
        <Styled.CompanyComparativeGradeCardWrapper item xs={12} md={4}>
            {
                department === Department.Default ?
                    mediaCard(
                        <>
                            {
                                companyComparativeGradeFrontAndBackCard(
                                    "companyComparativeGradeFrontCard",
                                    "Overall Company Grade",
                                    {
                                        "totalScore": companyComparativeGradeData.overall_company_comparative_total_score,
                                        "zIndex": companyComparativeGradeData.overall_company_comparative_z_score
                                    },
                                    {
                                        "grade": overallGradeAndEfficiencyLevel.grade,
                                        "efficiencyLevel": overallGradeAndEfficiencyLevel.efficiencyLevel
                                    },
                                )
                            }
                        </>
                    )
                    :
                    mediaCard(
                        <>
                            {
                                companyComparativeGradeFrontAndBackCard(
                                    "companyComparativeGradeFrontCard",
                                    "Overall Department Grade",
                                    {
                                        "totalScore": departmentData ? departmentData.overall_department_comparative_total_score : 0,
                                        "zIndex": departmentData ? departmentData.overall_department_comparative_z_score : 0
                                    },
                                    {
                                        "grade": overallGradeAndEfficiencyLevel.grade,
                                        "efficiencyLevel": overallGradeAndEfficiencyLevel.efficiencyLevel
                                    },
                                )
                            }
                        </>
                    )
            }
        </Styled.CompanyComparativeGradeCardWrapper>
        <Grid item xs={12} md={4}>
            {
                mediaCard(<>
                    <Styled.MediaCardNameContainer><Styled.MediaCardName>Total number of attempts</Styled.MediaCardName></Styled.MediaCardNameContainer>
                    <Styled.MediaCardBody>{companyPlayersActivitiesData ? totalNumberOfAttempts : <Skeleton />}</Styled.MediaCardBody>
                </>)
            }
        </Grid>
        <Grid item xs={12} md={4}>
            {
                mediaCard(
                    <>
                        <Styled.MediaCardNameContainer>
                            <Styled.MediaCardName>Number of licenses used</Styled.MediaCardName>
                        </Styled.MediaCardNameContainer>
                        <Styled.MediaCardBody>{companyPlayersActivitiesData?.number_of_subscribed_players ?? <Skeleton />}</Styled.MediaCardBody>
                    </>,
                )
            }
        </Grid>
    </Grid>
}

export default CompanyDashboardInfo