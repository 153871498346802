import { createSlice } from '@reduxjs/toolkit';

const initialState: { latestChallengeAttempt: ChallengeAttempt | null } = { latestChallengeAttempt: null }
const latestChallengeAttemptSlice = createSlice({
    name: 'LatestChallengeAttempt',
    initialState,
    reducers: {
        setLatestChallengeAttempt: (state, action) => {
            state.latestChallengeAttempt = action.payload // eslint-disable-line no-param-reassign
            return state
        }
    },
});
export const { setLatestChallengeAttempt } = latestChallengeAttemptSlice.actions;
export default latestChallengeAttemptSlice.reducer;

