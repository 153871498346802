import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChallengeDialogPromptProps {
    showDialog: boolean,
    alertMessageForChallengeAttempt: {
        title: string,
        message: string,
    }
}
const initialState: ChallengeDialogPromptProps = { showDialog: false, alertMessageForChallengeAttempt: { title: "", message: "" } }
const challengeDialogPromptSlice = createSlice({
    name: 'ChallengeDialogPromptSlice',
    initialState,
    reducers: {
        setChallengeDialogPrompt: (state: ChallengeDialogPromptProps, action: PayloadAction<boolean>) => {
            state.showDialog = action.payload // eslint-disable-line no-param-reassign
            return state
        },
        setAlertMessageToChallengeDialog: (state: ChallengeDialogPromptProps, action: PayloadAction<{
            title: string,
            message: string,
        }>) => {
            state.alertMessageForChallengeAttempt = action.payload // eslint-disable-line no-param-reassign
            return state
        }
    },
});
export const { setChallengeDialogPrompt, setAlertMessageToChallengeDialog } = challengeDialogPromptSlice.actions;
export default challengeDialogPromptSlice.reducer;