/* eslint-disable no-nested-ternary */
import CalendarHeatmap, { ReactCalendarHeatmapValue } from 'react-calendar-heatmap';
import { LightTooltip } from '../../../../../../shared/components/Tooltip/Tooltip';
import { convertTimestampToDate } from '../../../../../../shared/helpers/utils';
import * as Styled from './CompanyPlayerGitHeatmapStyles';

function CompanyPlayerGitHeatmap({ companyPlayerHeatMap }: CompanyPlayerGitHeatmapProps) {
	const heatMapValues = companyPlayerHeatMap.map(({ timestamp, contributions, }) => ({
		date: convertTimestampToDate(timestamp),
		contributions,
		timestamp,
	}))

	const parseTimestampForTooltip = (value: HeatMap) => {
		const { timestamp, contributions } = value || '';
		const contributionsValue =
			contributions > 1
				? `${contributions} contributions`
				: `${contributions} contribution`;

		return value
			? `${contributionsValue}\n${convertTimestampToDate(timestamp)}`
			: 'No contributions';
	};

	const squareColorLabelData = [
		{
			tooltipTitle: "No contributions",
			backgroundColor: "#eeeeee"
		},
		{
			tooltipTitle: "1-9 contributions",
			backgroundColor: "#acd5f2"
		},
		{
			tooltipTitle: "10-19 contributions",
			backgroundColor: "#7fa8d1"
		},
		{
			tooltipTitle: "20-29 contributions",
			backgroundColor: "#49729b"
		},
		{
			tooltipTitle: "30+ contributions",
			backgroundColor: "#254e77"
		},
	]

	const setHeatMapClassValue = (heatMap: HeatMap) => {
		/* This normalizingValue contains value between (0 to 4) based on contribution range */
		let normalizingValue = 0
		if (!heatMap || heatMap.contributions === 0)
			normalizingValue = 0
		else if (heatMap.contributions > 0 && heatMap.contributions <= 9)
			normalizingValue = 1
		else if (heatMap.contributions > 9 && heatMap.contributions <= 19)
			normalizingValue = 2
		else if (heatMap.contributions > 19 && heatMap.contributions <= 29)
			normalizingValue = 3
		else if (heatMap.contributions > 29)
			normalizingValue = 4
		return `color-gitlab-${normalizingValue}`;
	}

	return (
		<Styled.CustomCalendarHeatmapWrapper>
			<CalendarHeatmap
				showWeekdayLabels
				titleForValue={(value: ReactCalendarHeatmapValue<string> | undefined) =>
					value
						? parseTimestampForTooltip(value as unknown as HeatMap)
						: 'No contributions'
				}
				classForValue={(value: ReactCalendarHeatmapValue<string> | undefined) =>
					value
						? setHeatMapClassValue(value as unknown as HeatMap)
						: setHeatMapClassValue({ timestamp: 0, contributions: 0 })
				}
				startDate={new Date().setFullYear(new Date().getFullYear() - 1)}
				endDate={new Date()}
				values={heatMapValues}
				weekdayLabels={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
			/>
			<Styled.SquareColorLabelWrapper>
				{squareColorLabelData.map(data => (
					<LightTooltip key={data.backgroundColor} title={data.tooltipTitle}>
						<Styled.SquareColorLabel backgroundColor={data.backgroundColor} />
					</LightTooltip>
				))}
			</Styled.SquareColorLabelWrapper>
			<Styled.GitActivities>Push</Styled.GitActivities>
		</Styled.CustomCalendarHeatmapWrapper>
	)
}
export default CompanyPlayerGitHeatmap
