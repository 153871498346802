/* eslint-disable no-unused-vars */

import { useContext, useEffect } from 'react'
import { OpenIDContext } from '../../api/auth'
import { WebsocketContext } from '../../api/hooks'
import AlertMessage from '../../shared/components/Alert/AlertMessage'

function DisplayAlert() {
	const { authenticated, profile, setIsGlobalAlertShown } = useContext(OpenIDContext)
	const { isWSConnectionEstablished, wsConnectionMessage } = useContext(WebsocketContext)
	const subscriptionCheckout = sessionStorage.getItem("subscriptionCheckout")
	const checkGlobalAlertClosedFromSessionStorage = sessionStorage.getItem("globalAlertClosed")
	let isAlertShown = false
	let alertMessage = ""
	let alertLink = ""
	if (authenticated && subscriptionCheckout && subscriptionCheckout === 'success') {
		isAlertShown = true
		alertMessage = "You have successfully subscribed. It may take a few minutes for your subscription to be applied."
		alertLink = ""
	}
	else if (authenticated && profile.isSubscribed) {
		if (profile.restrictions.subscribedRemainingAttempts === 0) {
			isAlertShown = true
			alertMessage = "You have run out of challenge attempts. "
		}
		else if (profile.restrictions.subscribedRemainingAttempts < 3) {
			isAlertShown = true
			alertMessage = `You have ${profile.restrictions.subscribedRemainingAttempts} challenge attempts left. `
		}
		alertLink = "Please upgrade your subscription plan"
	}
	if (authenticated && !isWSConnectionEstablished) {
		isAlertShown = wsConnectionMessage.length > 0
		alertMessage = wsConnectionMessage
		alertLink = ""
	}
	if (!("Notification" in window)) {
		isAlertShown = true
		alertMessage = "This browser does not support desktop notification"
		alertLink = ""
	}
	useEffect(() => {
		if (isAlertShown) {
			setIsGlobalAlertShown(checkGlobalAlertClosedFromSessionStorage !== "true")
		}
	}, [isAlertShown, setIsGlobalAlertShown, checkGlobalAlertClosedFromSessionStorage])
	return (
			isAlertShown ? <AlertMessage isAlertShown={checkGlobalAlertClosedFromSessionStorage !== "true" ? isAlertShown : false} alertMessage={alertMessage} alertLink={alertLink} /> : null
	);
}

export default DisplayAlert;
