/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: CommitBuild[] = []

const commitBuildLogsSlice = createSlice({
    name: 'CommitBuildLogs',
    initialState,
    reducers: {
        addCommitTestBuild: (state: CommitBuild[], action: PayloadAction<CommitBuild>) => {
            const searchCommitBuild = state.find((testBuild: CommitBuild) => testBuild.buildId === action.payload.buildId &&
                testBuild.commitId === action.payload.commitId &&
                testBuild.challengeSlug === action.payload.challengeSlug &&
                testBuild.gameSlug === action.payload.gameSlug
            )
            if (!searchCommitBuild) {
                state.push(action.payload)
            }
            return state
        },
        addCommitTestBuildStep: (state: CommitBuild[], action: PayloadAction<CommitBuild>) => {
            // try to get CommitBuild from the list
            let getCommitBuild = state.find((testBuild: CommitBuild) => testBuild.buildId === action.payload.buildId &&
                testBuild.commitId === action.payload.commitId &&
                testBuild.challengeSlug === action.payload.challengeSlug &&
                testBuild.gameSlug === action.payload.gameSlug
            )
            // if getCommitBuild does not exist, then create a fresh copy and push it to the list
            if (!getCommitBuild) {
                getCommitBuild = {
                    buildId: action.payload.buildId,
                    commitId: action.payload.commitId,
                    gameSlug: action.payload.gameSlug,
                    challengeSlug: action.payload.challengeSlug,
                    stages: {
                        stageNumber: 0,
                        steps: [],
                        totalSteps: 0
                    },
                    status: 'running',
                    testDuration: 0,
                    buildOutputMode: 2,
                }
                state.push(getCommitBuild)
            }

            // now start filling up all other properties in getCommitBuild 
            getCommitBuild.stages.stageNumber = action.payload.stages.stageNumber
            const getCommitBuildStepIndex = getCommitBuild.stages.steps.findIndex((testBuildStep: CommitBuildStep) =>
                testBuildStep.stepNumber === action.payload.stages.steps[0].stepNumber &&
                testBuildStep.stepName === action.payload.stages.steps[0].stepName
            )

            if (getCommitBuildStepIndex !== -1) {
                getCommitBuild.stages.steps[getCommitBuildStepIndex].logs.push(
                    {
                        log: action.payload.stages.steps[0].logs[0].log,
                        position: action.payload.stages.steps[0].logs[0].position,
                        id: action.payload.stages.steps[0].logs[0].id
                    }
                )
            } else {
                getCommitBuild.stages.steps.push({
                    stepNumber: action.payload.stages.steps[0].stepNumber,
                    stepName: action.payload.stages.steps[0].stepName,
                    logs: [
                        {
                            log: action.payload.stages.steps[0].logs[0].log,
                            position: action.payload.stages.steps[0].logs[0].position,
                            id: new Date().getTime()
                        }],
                })
            }
            return state
        },
        updateCommitTestBuildStatus: (state: CommitBuild[], action: PayloadAction<CommitBuild>) => {
            // try to get CommitBuild from the list
            let getCommitBuild = state.find((testBuild: CommitBuild) => testBuild.buildId === action.payload.buildId &&
                testBuild.commitId === action.payload.commitId &&
                testBuild.challengeSlug === action.payload.challengeSlug &&
                testBuild.gameSlug === action.payload.gameSlug
            )
            // if getCommitBuild does not exist, then create a fresh copy and push it to the list
            if (!getCommitBuild) {
                getCommitBuild = {
                    buildId: action.payload.buildId,
                    commitId: action.payload.commitId,
                    gameSlug: action.payload.gameSlug,
                    challengeSlug: action.payload.challengeSlug,
                    stages: {
                        stageNumber: 0,
                        steps: [],
                        totalSteps: 0
                    },
                    status: 'running',
                    testDuration: 0,
                    buildOutputMode: 2,
                }
                state.push(getCommitBuild)
            }

            // now start filling up all other properties in getCommitBuild 
            getCommitBuild.status = action.payload.status
            getCommitBuild.testDuration = action.payload.testDuration
            getCommitBuild.stages.totalSteps = action.payload.stages.totalSteps
            return state
        },
        removeCommitTestBuild: (state: CommitBuild[], action: PayloadAction<{ commitId: string, gameSlug: string, challengeSlug: string }>) => {
            const searchCommitBuild = state.find((testBuild: CommitBuild) =>
                testBuild.commitId === action.payload.commitId &&
                testBuild.challengeSlug === action.payload.challengeSlug &&
                testBuild.gameSlug === action.payload.gameSlug
            )
            if (searchCommitBuild) {
                state = state.filter((testBuild: CommitBuild) => testBuild !== searchCommitBuild)
            }
            return state
        },
    },
});

export const { addCommitTestBuild, addCommitTestBuildStep, updateCommitTestBuildStatus, removeCommitTestBuild } = commitBuildLogsSlice.actions;
export default commitBuildLogsSlice.reducer;

